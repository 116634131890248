import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Header, Table } from 'semantic-ui-react';
import withRouter from 'react-router/withRouter';
import { withLocalize, getTranslate, getActiveLanguage } from 'react-localize-redux';

import { storeSelectedProduct, addProductToCart } from '../actions/wccd-content';
import checkUser from "../functions/check-user";

class SearchProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: this.props.products
        };
    }

    handleRowClick = (searchItem) => {
        this.props.location.state.wccdObject.machineTypes.forEach((machineTypesItem) => {
            machineTypesItem.children.forEach((machineTypeChild) => {
                machineTypeChild.product_categories.forEach((productCategoryItem) => {
                    if (searchItem.parentSlug === productCategoryItem.slug) {
                        this.props.storeSelectedProduct(productCategoryItem);

                        setTimeout(() => {
                            this.props.history.push('/category/' + searchItem.categorySlug);
                        }, 2000);
                    }
                });
            });
        });
    };

    _sendToCart = (productId) => {
        let noOfProductsSelected = this.state.products.find((products) => {
            return products.id === productId;
        });
        let valuesToCart = {
            productId: noOfProductsSelected.id,
            numOfProducts: 1,
        };
        this.props.addProductToCart(valuesToCart);
    };

    render() {
        const { translate, user } = this.props;
        const userExist = checkUser(user);
        let userIsOrderManager = false;
        if (user.isOrderManager) {
            userIsOrderManager = true;
        }
        let searchResults = [];
        let searchTerm = "";
        if (this.props.location.state !== undefined) {
            searchResults = this.props.location.state.searchResults;
            searchTerm = this.props.location.state.searchTerm;
        }

        return (
            <div className="content">
				<div className="flex-1">
					<Header as="h2">{translate('search_results_for')}: {searchTerm}</Header>
                    <Table singleLine compact selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{translate('category')}</Table.HeaderCell>
                                <Table.HeaderCell>{translate('artno')}</Table.HeaderCell>
                                <Table.HeaderCell>{translate('description')}</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {searchResults.map((searchItem, index) => {
                                return (
                                    <Table.Row key={index} onClick={() => this.handleRowClick(searchItem)} style={{"cursor":"pointer"}}>
                                        <Table.Cell>{searchItem.categoryPathString}</Table.Cell>
                                        <Table.Cell>{searchItem.sku}</Table.Cell>
                                        <Table.Cell>{searchItem.title}</Table.Cell>
                                        <Table.Cell textAlign="center" className="no-print">
                                            {userExist && !userIsOrderManager && (
                                                <Icon
                                                    link
                                                    name="add to cart"
                                                    onClick={(e) => { e.stopPropagation(); this._sendToCart(searchItem.id); }}
                                                />
                                            )}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
	user: state.user,
    products: state.wccd.products,
	countries: state.wccd.countries,
	translate: getTranslate(state.localize),
	currentLanguage: getActiveLanguage(state.localize)
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ addProductToCart, storeSelectedProduct }, dispatch);
};

SearchProducts = withLocalize(SearchProducts);
SearchProducts = connect(mapStateToProps, mapDispatchToProps)(SearchProducts);
SearchProducts = withRouter(SearchProducts);

export default SearchProducts;

import { TOGGLE_CART_EDIT, DO_STEP, SET_ERROR, LOAD_SAVED_DATA_AFTER_UPDATE_RESTART } from '../actions/action-types';

export const editCart = (cart) => {
	return {
		type: TOGGLE_CART_EDIT,
		payload: {
			cart
		}
	};
};

export const doStep = (step) => {
	return {
		type: DO_STEP,
		payload: {
			step
		}
	};
};

export const setError = (msg) => {
	return {
		type: SET_ERROR,
		payload: {
			msg
		}
	};
};

export const loadSavedDataAfterUpdate = (userData, cartData, saveProductItems) => {
	return {
		type: LOAD_SAVED_DATA_AFTER_UPDATE_RESTART,
		payload: {
			userData,
			cartData,
			saveProductItems
		}
	};
}
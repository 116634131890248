
import React, { Component } from 'react';
import axios from 'axios';
import { Grid, Form, Button, Icon, List, Input } from 'semantic-ui-react';

import config from '../config/config';

class CeOchMotorintygSok extends Component {
	constructor(props) {
		super(props);
		this.state = {
            searchValue: '',
            isLoading: false,
            hasMadeRequest: false,
            resultItems: []
		};
    }

    handleFormSubmit = () => {
        const searchValue = this.state.searchValue;
        if (!this.state.hasMadeRequest) {
            if (searchValue.length > 1) { 
                this.setState({
                    isLoading: true,
                    hasMadeRequest: true
                });

                const postData = {
                    query: searchValue
                };
                axios.post(config.API_ENDPOINTS.SEARCH_CE_OCH_MOTORINTYG, postData)
                .then(res => {
                    this.setState({
                        isLoading: false,
                        hasMadeRequest: false
                    });
                    if (res.data) {
                        this.setState({ resultItems: res.data });
                    }
                });
            }
        }
    }

    handleInputChange = (e) => {
        this.setState({
            searchValue: e.target.value
        });
    }

    render() {
        const { resultItems } = this.state;
        const ceMotorintygDocumentFolder = 'https://spareparts.swepac.com/watch_dir/ce-motorintyg-pdf-documents/';

        return (
            <Grid columns={1} stackable>
                <Grid.Column>
                    <Form onSubmit={this.handleFormSubmit}>
                        <Form.Group>
                            <Form.Field inline>
                                <label>Serial number:</label>
                                <Input
                                    placeholder={'Serial number'}
                                    value={this.state.searchValue}
                                    onChange={this.handleInputChange}
                                />
                            </Form.Field>
                            <Button
                                color='red'
                                icon
                                labelPosition='left'
                                onClick={this.handleFormSubmit}
                                loading={this.state.isLoading}
                            >Search<Icon name='search' />
                            </Button>
                        </Form.Group>
                    </Form>
                </Grid.Column>
                <Grid.Column>
                    <List celled>
                        {resultItems.map((item, index) => {
                            return (
                            <List.Item key={index}>
                                <br />
                                <b>{index+1}. Product: {item.modelName.replace('_', ' ')}, Serial: {item.serialNum}</b><br />
                                <List bulleted>
                                    {item.showCeDocumentLink &&
                                        <List.Item>
                                            <a href={ceMotorintygDocumentFolder + 'ce-' + item.serialNum + '.pdf'} 
                                            target='_blank' rel='noopener noreferrer'>CE deklaration (Download)</a>
                                        </List.Item>
                                    }
                                    {item.showMotorintygDocumentLink &&
                                        <List.Item>
                                            <a href={ceMotorintygDocumentFolder + 'motorintyg-' + item.serialNum + '.pdf'} 
                                            target='_blank' rel='noopener noreferrer'>Motorintyg (Download)</a>
                                        </List.Item>
                                    }
                                </List>
                                <br />
                            </List.Item>
                            );
                        })}
                    </List>
                </Grid.Column>
            </Grid>
        )
    }
}

export default CeOchMotorintygSok;
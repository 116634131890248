import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  withLocalize,
  getTranslate,
  getLanguages,
  getActiveLanguage,
  setActiveLanguage,
} from "react-localize-redux";
import {
  Container,
  Responsive,
  Menu,
  Dropdown,
  Flag,
  Modal,
  Header,
  Button,
  Icon,
} from "semantic-ui-react";

import config from "../config/config";

import {
  allWccd,
  emptyCart,
  storeSelectedProduct,
  isLoading,
  updateActiveCurrency,
} from "../actions/wccd-content";
import { logoutUser } from "../actions/user-action";
import { editCart, doStep } from "../actions/settings-action";

import checkUser from "../functions/check-user";
import updatedCart from "../functions/updated-cart";

import Login from "../components/login";
import Cart from "../components/cart";
import LogoItem from "../components/menu/logo-item";
import ProductsItem from "../components/menu/products-item";
import CartButton from "../components/menu/cart-button";
import CartMenu from "../components/menu/cart-menu";
import UserDropdown from "../components/menu/user-dropdown";
import MenuSearchBox from "../components/menu/menu-searchbox";
import OrderAdminDropdown from "../components/menu/order-admin-dropdown";
import CeAdminDropdown from "../components/menu/ce-admin-dropdown";
import LangItem from "../components/menu/lang-item";

class PageHeaderResponsive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      loginOpen: false,
      newProductPopup: false,
      addedProduct: "",
      noOfProducts: 0,
      fading: null,
      addedProductTimer: 3000,
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.settings.editCart) {
      let newCart = updatedCart(prevProps.cart, this.props.cart);

      if (newCart !== null) {
        let addedProduct = this.props.wccd.products.find((products) => {
          return products.id === newCart.id;
        });

        clearTimeout(this.state.fading);
        if (this.props.currentLanguage) {
          this.setState({
            newProductPopup: true,
            addedProduct:
              addedProduct.lang_name[this.props.currentLanguage.code],
            noOfProducts: newCart.numOfProducts,
            fading: setTimeout(() => {
              this.setState({
                newProductPopup: false,
                addedProduct: "",
                noOfProducts: 0,
                fading: null,
              });
            }, this.state.addedProductTimer),
          });
        }
      }
    }
    if (this.state.loginOpen === true && checkUser(this.props.user) === true) {
      this.setState({ loginOpen: false });
    }
  }

  /** Private functions */

  _toogleModal = () => {
    const boolEditCart = this.state.modalOpen === true ? false : true;
    this.setState({ modalOpen: boolEditCart });
    this.props.editCart(boolEditCart);
  };

  _toogleLogin = () => {
    const loginOpen = this.state.loginOpen === true ? false : true;
    this.setState({ loginOpen: loginOpen });
  };

  _handleLanguageSelect = (e, { value }) => {
    this.props.setActiveLanguage(value);
  };

  _handleCurrencySelect = (e, { value }) => {
    this.props.updateActiveCurrency(value);
  };

  _emptyCart = () => {
    const textTranslation = this.props.translate("empty_cart");
    let confirmEmpty = window.confirm(textTranslation);
    if (confirmEmpty === true) {
      this.props.emptyCart();
      this.props.editCart(false);
      this.setState({ modalOpen: false });
    }
  };

  _gotoPage = (page) => {
    this.props.history.push(page);
  };

  _gotoSwepacSite = () => {
    window.location.href = "https://swepac.com/";
  };

  _gotoCheckout = () => {
    this.setState({ modalOpen: false });
    this.props.doStep(1);
    this._gotoPage("/checkout/1");
  };

  _gotoSelectedProduct = () => {
    const item = this.props.wccd.selectedProduct;
    let product = {
      id: item.id,
      lang_name: JSON.parse(item.name),
      slug: item.slug,
      product_catalog_image_url: item.img,
    };
    this.props.isLoading(true);
    this.props.storeSelectedProduct(product);
    this._gotoPage("/category/" + this.props.wccd.selectedProduct.slug);
  };

  _gotoLogout = () => {
    this.props.logoutUser();
    this._gotoPage("/");
  };

  _getNumberNoOfProductsInCart(cart) {
    return {
      totalCount: cart.length,
      subCount: 0,
    };
  }

  _getSelectedLanguages(languages) {
    let languagesToSelect = languages.map((language) => {
      if (language.code === "en") {
        language.flag = "gb";
      } else {
        language.flag = language.code;
      }
      return {
        key: language.code,
        value: language.code,
        text: <Flag name={language.flag} />,
      };
    });
    return languagesToSelect;
  }

  _getCurrencies(currencies) {
    let currenciesToSelect = currencies.map((currency) => {
      return {
        key: currency.key,
        value: currency.key,
        text: <Fragment>{currency.name}</Fragment>,
      };
    });
    return currenciesToSelect;
  }

  render() {
    let languagesToSelect = this._getSelectedLanguages(this.props.languages);
    let currenciesToSelect = [];
    if (this.props.wccd.currencies.length > 0) {
      currenciesToSelect = this._getCurrencies(this.props.wccd.currencies);
    }
    let countCart = this._getNumberNoOfProductsInCart(this.props.cart);
    let userExist = checkUser(this.props.user);
    const userIsOrderMan = this.props.user.isOrderManager;
    const userIsCeAdmin = this.props.user.isCeAdmin;
    let currentLanguageId = "en";
    if (this.props.currentLanguage !== undefined) {
      currentLanguageId = this.props.currentLanguage.code;
    }
    let currentCurrencyId = "";
    if (this.props.wccd.selectedCurrency) {
      currentCurrencyId = this.props.wccd.selectedCurrency;
    } else {
      currentCurrencyId = "sek";
    }
    const { translate } = this.props;

    let menuSearchBoxMarginTopValue = "4px";
    if (userIsOrderMan || userIsCeAdmin) {
      menuSearchBoxMarginTopValue = "0px";
    }

    return (
      <Fragment>
        <img
          src={config.themeLocation + "/images/swepac-logo.png"}
          alt="Swepac"
          className="header-logo-size only-print no-margin show-cursor"
        />
        <Menu
          fixed="top"
          fluid
          inverted
          borderless
          className="main-page-top no-print"
        >
          <Container>
            <Responsive as={Fragment} minWidth={1000}>
              <LogoItem
                themeLocation={config.themeLocation}
                gotoPage={this._gotoSwepacSite}
                fitted={true}
                compact={false}
              />
              {userIsOrderMan && (
                <OrderAdminDropdown simple={true} gotoPage={this._gotoPage} />
              )}
              {userIsCeAdmin && (
                <CeAdminDropdown simple={true} gotoPage={this._gotoPage} />
              )}
              <ProductsItem
                gotoPage={this._gotoPage}
                simple={true}
                doTranslate={translate}
                selectedProduct={this.props.wccd.selectedProduct}
                selectedLanguageCode={currentLanguageId}
                gotoSelectedProduct={this._gotoSelectedProduct}
              />
              <UserDropdown
                userExist={userExist}
                gotoPage={this._gotoPage}
                toogleLogin={this._toogleLogin}
                gotoLogout={this._gotoLogout}
                doTranslate={translate}
                simple={true}
                languagesToSelect={languagesToSelect}
                currentLanguageId={currentLanguageId}
                currenciesToSelect={currenciesToSelect}
                currentCurrencyId={currentCurrencyId}
                handleLanguageSelect={this._handleLanguageSelect}
                handleCurrencySelect={this._handleCurrencySelect}
                userIsOrderMan={userIsOrderMan}
                userIsCeAdmin={userIsCeAdmin}
              />
              {!userIsCeAdmin && (
                <LangItem
                  languagesToSelect={languagesToSelect}
                  currentLanguageId={currentLanguageId}
                  handleLanguageSelect={this._handleLanguageSelect}
                  simple={true}
                />
              )}
              <MenuSearchBox
                wccdObject={this.props.wccd}
                selectedLanguageCode={currentLanguageId}
                gotoPage={this._gotoPage}
                colWidth={8}
                marginTopValue={menuSearchBoxMarginTopValue}
                marginLeftValue="-5px"
                marginRightValue="0px"
                searchBoxWidth="200px"
                alignedValue="left"
                history={this.props.history}
                doTranslate={translate}
              />
              <Menu.Item position="right" className="top-menu-item" />
              {!userIsOrderMan && !userIsCeAdmin && (
                <CartButton
                  userExist={userExist}
                  toogleModal={this._toogleModal}
                  countCart={countCart}
                  state={{
                    noOfProducts: this.state.noOfProducts,
                    addedProduct: this.state.addedProduct,
                    newProductPopup: this.state.newProductPopup,
                    fading: this.state.fading,
                  }}
                  position=""
                  hideCartButtonText={false}
                />
              )}
            </Responsive>
            <Responsive as={Fragment} maxWidth={999} minWidth={790}>
              <LogoItem
                themeLocation={config.themeLocation}
                gotoPage={this._gotoPage}
                fitted={false}
                compact={false}
              />
              <Dropdown
                item
                text={translate("open_main_menu")}
                className="top-menu-item"
              >
                <Dropdown.Menu>
                  {userIsOrderMan && (
                    <OrderAdminDropdown
                      simple={false}
                      gotoPage={this._gotoPage}
                    />
                  )}
                  {userIsCeAdmin && (
                    <CeAdminDropdown simple={true} gotoPage={this._gotoPage} />
                  )}
                  <ProductsItem
                    gotoPage={this._gotoPage}
                    simple={false}
                    doTranslate={translate}
                    selectedProduct={this.props.wccd.selectedProduct}
                    selectedLanguageCode={currentLanguageId}
                    gotoSelectedProduct={this._gotoSelectedProduct}
                  />
                  <Dropdown.Divider />
                  <UserDropdown
                    userExist={userExist}
                    gotoPage={this._gotoPage}
                    toogleLogin={this._toogleLogin}
                    gotoLogout={this._gotoLogout}
                    doTranslate={translate}
                    simple={false}
                    languagesToSelect={languagesToSelect}
                    currentLanguageId={currentLanguageId}
                    currenciesToSelect={currenciesToSelect}
                    currentCurrencyId={currentCurrencyId}
                    handleLanguageSelect={this._handleLanguageSelect}
                    handleCurrencySelect={this._handleCurrencySelect}
                    userIsOrderMan={userIsOrderMan}
                    userIsCeAdmin={userIsCeAdmin}
                  />
                </Dropdown.Menu>
              </Dropdown>
              {!userIsOrderMan && !userIsCeAdmin && (
                <LangItem
                  languagesToSelect={languagesToSelect}
                  currentLanguageId={currentLanguageId}
                  handleLanguageSelect={this._handleLanguageSelect}
                  simple={false}
                />
              )}
              {!userIsOrderMan && !userIsCeAdmin && (
                <CartButton
                  userExist={userExist}
                  toogleModal={this._toogleModal}
                  countCart={countCart}
                  state={{
                    noOfProducts: this.state.noOfProducts,
                    addedProduct: this.state.addedProduct,
                    newProductPopup: this.state.newProductPopup,
                    fading: this.state.fading,
                  }}
                  position="right"
                  hideCartButtonText={true}
                />
              )}
              <MenuSearchBox
                wccdObject={this.props.wccd}
                selectedLanguageCode={currentLanguageId}
                gotoPage={this._gotoPage}
                colWidth={8}
                marginTopValue="10px"
                marginLeftValue="-10px"
                marginRightValue="0px"
                searchBoxWidth="140px"
                alignedValue="right"
                history={this.props.history}
                doTranslate={translate}
              />
            </Responsive>
            <Responsive as={Fragment} maxWidth={789}>
              <LogoItem
                themeLocation={config.themeLocation}
                gotoPage={this._gotoPage}
                fitted={false}
                compact={true}
              />

              <MenuSearchBox
                wccdObject={this.props.wccd}
                selectedLanguageCode={currentLanguageId}
                gotoPage={this._gotoPage}
                colWidth={8}
                marginTopValue="12px"
                marginLeftValue="-40px"
                marginRightValue="0px"
                searchBoxWidth="100px"
                alignedValue="left"
                history={this.props.history}
                doTranslate={translate}
              />

              <Menu.Item position="right" style={{ marginRight: "-20px" }}>
                {!userIsOrderMan && !userIsCeAdmin && (
                  <LangItem
                    position="right"
                    languagesToSelect={languagesToSelect}
                    currentLanguageId={currentLanguageId}
                    handleLanguageSelect={this._handleLanguageSelect}
                    simple={false}
                  />
                )}
                <Dropdown
                  item
                  icon="bars"
                  position="right"
                  className="top-menu-item"
                  style={{ marginRight: "0px" }}
                >
                  <Dropdown.Menu direction="left">
                    {!userIsOrderMan && !userIsCeAdmin && (
                      <CartMenu
                        userExist={userExist}
                        toogleModal={this._toogleModal}
                        countCart={countCart}
                        state={{
                          noOfProducts: this.state.noOfProducts,
                          addedProduct: this.state.addedProduct,
                          newProductPopup: this.state.newProductPopup,
                          fading: this.state.fading,
                        }}
                        position="right"
                      />
                    )}
                    {userIsOrderMan && (
                      <OrderAdminDropdown
                        simple={false}
                        gotoPage={this._gotoPage}
                      />
                    )}
                    {userIsCeAdmin && (
                      <CeAdminDropdown
                        simple={true}
                        gotoPage={this._gotoPage}
                      />
                    )}
                    <Dropdown.Divider />
                    <ProductsItem
                      gotoPage={this._gotoPage}
                      simple={false}
                      doTranslate={translate}
                      selectedProduct={this.props.wccd.selectedProduct}
                      selectedLanguageCode={currentLanguageId}
                      gotoSelectedProduct={this._gotoSelectedProduct}
                    />
                    <UserDropdown
                      userExist={userExist}
                      gotoPage={this._gotoPage}
                      toogleLogin={this._toogleLogin}
                      gotoLogout={this._gotoLogout}
                      doTranslate={translate}
                      simple={false}
                      languagesToSelect={languagesToSelect}
                      currentLanguageId={currentLanguageId}
                      currenciesToSelect={currenciesToSelect}
                      currentCurrencyId={currentCurrencyId}
                      handleLanguageSelect={this._handleLanguageSelect}
                      handleCurrencySelect={this._handleCurrencySelect}
                      userIsOrderMan={userIsOrderMan}
                      userIsCeAdmin={userIsCeAdmin}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Item>
            </Responsive>
          </Container>
        </Menu>

        <Modal
          open={this.state.modalOpen}
          onClose={this._toogleModal}
          style={{ overflowX: "scroll" }}
        >
          <Modal.Content>
            <Header icon="cart" content={translate("cart")} />
            <Cart />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this._toogleModal} color="grey" floated="left">
              <Icon name="remove" /> {translate("close")}
            </Button>
            <Button onClick={this._emptyCart} floated="left">
              <Icon name="cart" /> {translate("cart_empty")}
            </Button>
            <Button color="blue" onClick={this._gotoCheckout} floated="right">
              <Icon name="cart" /> {translate("goto_register")}
            </Button>
            <div className="clear-float" />
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.loginOpen}
          onClose={this._toogleLogin}
          size="tiny"
        >
          <Modal.Content>
            <Login />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this._toogleLogin} color="grey">
              <Icon name="remove" /> {translate("close")}
            </Button>

            <div className="clear-float" />
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings,
  wp: state.wp,
  wccd: state.wccd,
  cart: state.wccd.cart,
  user: state.user,
  translate: getTranslate(state.localize),
  currentLanguage: getActiveLanguage(state.localize),
  languages: getLanguages(state.localize),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      allWccd,
      emptyCart,
      logoutUser,
      editCart,
      doStep,
      setActiveLanguage,
      storeSelectedProduct,
      isLoading,
      updateActiveCurrency,
    },
    dispatch
  );
};

PageHeaderResponsive = withLocalize(PageHeaderResponsive);
PageHeaderResponsive = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageHeaderResponsive);
PageHeaderResponsive = withRouter(PageHeaderResponsive);

export default PageHeaderResponsive;

import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import axios from "axios";
import {
  Header,
  Form,
  Grid,
  Dropdown,
  Segment,
  Message,
  Button,
  Modal,
  Icon,
  Input,
  Dimmer,
  Loader,
  Checkbox,
} from "semantic-ui-react";
import withRouter from "react-router/withRouter";
import config from "../config/config";
import { Link } from "react-router-dom";

import { orderAdminUpdateUser } from "../actions/user-action";
import { getTranslate } from "react-localize-redux";

class OrderAdminUserDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saveButtonIsLoading: false,
      checkoutIsLoading: false,
      acceptPendingUserIsLoading: false,
      acceptPendingUserPromptIsOpen: false,
      addCustomDeliveryAddressPromptIsOpen: false,
      addCustomDeliveryAddressButtonIsEnabled: false,
      pageIsLoading: false,
      createNewMainCustomerModalIsOpen: false,
      editMainCustomerModalIsOpen: false,
      addCustomDeliveryAddressDeliveryIdInput: "",
      isEditingMainCustomerItem_name: "",
      isEditingMainCustomerItem_customerNumber: "",
      isEditingMainCustomerItem_discount: "0",
      isEditingMainCustomerItem_noPyramidImport: false,
      isEditingMainCustomerItem_id: "",
      delivery_address_options: [],
      first_name: "",
      last_name: "",
      main_customer_id: "",
      delivery_address_id: "",
      shipping_first_name: "",
      shipping_last_name: "",
      shipping_address_1: "",
      shipping_address_2: "",
      shipping_postcode: "",
      shipping_city: "",
      shipping_country: "",
      mainCustomerCountry: "none",
      createNewMainCustomer_nameValue: "",
      createNewMainCustomer_customerNumberValue: "",
      createNewMainCustomer_discountValue: "0",
      createNewMainCustomer_noPyramidImportValue: false,
      createNewMainCustomer_country: "none",
      mainCustomerItems: [],
    };
  }

  _getDeliveryAddressOptionsForMainCustomerId = (mainCustomerIdValue) => {
    let mainCustomerItem = this.state.mainCustomerItems.find(
      (mainCustomerItem) => {
        return mainCustomerItem.id === mainCustomerIdValue;
      }
    );
    return mainCustomerItem.delivery_adresses.map(
      (deliveryAddressItem, index) => ({
        key: deliveryAddressItem.id,
        text:
          "#" +
          deliveryAddressItem.delivery_address_id +
          " - " +
          deliveryAddressItem.title,
        value: deliveryAddressItem.id,
      })
    );
  };

  _handleChange = (e, { name, value }) => {
    if (name === "main_customer_id") {
      this.setState({
        [name]: value,
        delivery_address_options: this._getDeliveryAddressOptionsForMainCustomerId(
          value
        ),
      });
    } else if (name === "addCustomDeliveryAddressDeliveryIdInput") {
      if (value.toString().trim().length > 0) {
        if (
          this.state.shipping_address_1 !== "" &&
          this.state.shipping_postcode !== "" &&
          this.state.shipping_city !== ""
        ) {
          this.setState({
            addCustomDeliveryAddressButtonIsEnabled: true,
          });
        }
      } else {
        this.setState({
          addCustomDeliveryAddressButtonIsEnabled: false,
        });
      }
      this.setState({
        [name]: value,
      });
    } else if (
      name === "shipping_address_1" ||
      name === "shipping_postcode" ||
      name === "shipping_city"
    ) {
      if (
        this.state.shipping_address_1 !== "" &&
        this.state.shipping_postcode !== "" &&
        this.state.shipping_city !== "" &&
        this.state.addCustomDeliveryAddressDeliveryIdInput !== "" &&
        value !== ""
      ) {
        this.setState({
          [name]: value,
          addCustomDeliveryAddressButtonIsEnabled: true,
        });
      } else {
        this.setState({
          [name]: value,
          addCustomDeliveryAddressButtonIsEnabled: false,
        });
      }
    } else if (
      name === "createNewMainCustomer_discountValue" ||
      name === "isEditingMainCustomerItem_discount"
    ) {
      if (!isNaN(value)) {
        if (parseInt(value) > 100) {
          value = 100;
        }
        if (value.length < 1) {
          value = 0;
        } else if (value.length > 0) {
          if (value[0].toString() === "0") {
            value = value.substr(1);
          }
        }
        this.setState({
          [name]: value,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  _onCheckOut = (e, dataSaveObject) => {
    e.preventDefault();
    this.setState({ checkoutIsLoading: true });

    const inProgressRole = { role: 'in_progress' };

    this.props
      .orderAdminUpdateUser(
        {
          ...dataSaveObject,
          ...inProgressRole
        },
        this.props.user.gId,
        "save-user-in-progress"
      )
      .then(() => {
        this.setState({ checkoutIsLoading: false });
        this.props.history.push("/order-admin/users");
      });
  };

  _handleSubmit = (e, dataSaveObject) => {
    e.preventDefault();
    this.setState({
      saveButtonIsLoading: true,
    });

    this.props
      .orderAdminUpdateUser(
        dataSaveObject,
        this.props.user.gId,
        "save-user-info"
      )
      .then(() => {
        this.setState({
          saveButtonIsLoading: false,
        });
        this.props.history.push("/order-admin/users");
      });
  };

  _acceptPendingUser = (e) => {
    e.preventDefault();
    this.setState({
      acceptPendingUserIsLoading: true,
      acceptPendingUserPromptIsOpen: true,
    });
  };

  closeModalWindow = (modalName, answerYes, sentObject = {}) => {
    if (modalName === "accept-user") {
      this.setState({
        acceptPendingUserPromptIsOpen: false,
      });

      if (answerYes) {
        this.props
          .orderAdminUpdateUser(
            sentObject,
            this.props.user.gId,
            "accept-pending-user"
          )
          .then(() => {
            this.setState({
              acceptPendingUserIsLoading: false,
            });
            this.props.history.push("/order-admin/users");
          });
      } else {
        this.setState({
          acceptPendingUserIsLoading: false,
        });
      }
    } else if (modalName === "add-as-delivery-address") {
      this.setState({ addCustomDeliveryAddressPromptIsOpen: false });
      if (answerYes) {
        const postObject = sentObject.postObject;
        const mainCustomerId = sentObject.mainCustomerId;
        this.setState({
          pageIsLoading: true,
        });

        this.props
          .orderAdminUpdateUser(
            postObject,
            this.props.user.gId,
            "add-user-shipping-address-as-a-new-delivery-address"
          )
          .then(() => {
            const urlPath =
              config.API_ENDPOINTS.ORDER_ADMIN_GET_USERS +
              "?onlyGetMainCustomers=true&gId=" +
              this.props.user.gId;

            Promise.all([axios.get(urlPath)])
              .then((resp) => {
                const mainCustomersResult = resp[0].data;
                this.setState({
                  mainCustomerItems: mainCustomersResult,
                  addCustomDeliveryAddressDeliveryIdInput: "",
                });
                setTimeout(() => {
                  this.setState({
                    pageIsLoading: false,
                    delivery_address_options: this._getDeliveryAddressOptionsForMainCustomerId(
                      mainCustomerId
                    ),
                  });
                }, 1000);
              })
              .catch((error) => {
                console.log("error allWccd", error);
              });
          });
      }
    }
  };

  addCustomDeliveryAddressToMainCustomer = (e) => {
    e.preventDefault();
    this.setState({
      addCustomDeliveryAddressPromptIsOpen: true,
    });
  };

  addNewMainCustomerButtonPressed = (e) => {
    e.preventDefault();
    this.setState({
      createNewMainCustomerModalIsOpen: true,
    });
  };

  editMainCustomerButtonPressed = (e, mainCustomerItem) => {
    e.preventDefault();
    if (mainCustomerItem) {
      this.setState({
        editMainCustomerModalIsOpen: true,
        isEditingMainCustomerItem_name: mainCustomerItem.name,
        isEditingMainCustomerItem_customerNumber:
          mainCustomerItem.main_customer_id,
        mainCustomerCountry: mainCustomerItem.mainCustomerCountry,
        isEditingMainCustomerItem_discount: mainCustomerItem.discount,
        isEditingMainCustomerItem_noPyramidImport:
          mainCustomerItem.no_pyramid_export,
        isEditingMainCustomerItem_id: mainCustomerItem.id,
      });
    }
  };

  closeEditMainCustomerButtonPressed = (saveCustomer) => {
    if (saveCustomer) {
      this.setState({
        pageIsLoading: true,
      });

      let editMainCustomerObject = {
        name: this.state.isEditingMainCustomerItem_name,
        customerNumber: this.state.isEditingMainCustomerItem_customerNumber,
        discount: this.state.isEditingMainCustomerItem_discount,
        noPyramidImport: this.state.isEditingMainCustomerItem_noPyramidImport,
        mainCustomerPostId: this.state.isEditingMainCustomerItem_id,
        mainCustomerCountry: this.state.mainCustomerCountry,
      };

      this.props
        .orderAdminUpdateUser(
          editMainCustomerObject,
          this.props.user.gId,
          "save-main-customer-edits"
        )
        .then(() => {
          const urlPath =
            config.API_ENDPOINTS.ORDER_ADMIN_GET_USERS +
            "?onlyGetMainCustomers=true&gId=" +
            this.props.user.gId;

          Promise.all([axios.get(urlPath)])
            .then((resp) => {
              const mainCustomersResult = resp[0].data;
              this.setState({
                pageIsLoading: false,
                mainCustomerItems: mainCustomersResult,
              });
            })
            .catch((error) => {
              console.log("error allWccd", error);
            });
        });
    }

    this.setState({
      editMainCustomerModalIsOpen: false,
      isEditingMainCustomerItem_name: "",
      isEditingMainCustomerItem_customerNumber: "",
      isEditingMainCustomerItem_discount: "0",
      isEditingMainCustomerItem_noPyramidImport: false,
      isEditingMainCustomerItem_id: "",
    });
  };

  closeMainCustomerModal = (shouldCreateMainCustomer) => {
    if (shouldCreateMainCustomer) {
      this.setState({
        pageIsLoading: true,
      });

      let newMainCustomerObject = {
        name: this.state.createNewMainCustomer_nameValue,
        customerNumber: this.state.createNewMainCustomer_customerNumberValue,
        discount: this.state.createNewMainCustomer_discountValue,
        noPyramidImport: this.state.createNewMainCustomer_noPyramidImportValue,
        mainCustomerCountry: this.state.createNewMainCustomer_country,
      };
      this.props
        .orderAdminUpdateUser(
          newMainCustomerObject,
          this.props.user.gId,
          "create-new-main-customer"
        )
        .then(() => {
          const urlPath =
            config.API_ENDPOINTS.ORDER_ADMIN_GET_USERS +
            "?onlyGetMainCustomers=true&gId=" +
            this.props.user.gId;

          Promise.all([axios.get(urlPath)])
            .then((resp) => {
              const mainCustomersResult = resp[0].data;
              this.setState({
                pageIsLoading: false,
                mainCustomerItems: mainCustomersResult,
              });
            })
            .catch((error) => {
              console.log("error allWccd", error);
            });
        });
    }
    this.setState({
      createNewMainCustomerModalIsOpen: false,
      createNewMainCustomer_nameValue: "",
      createNewMainCustomer_customerNumberValue: "",
      createNewMainCustomer_discountValue: "0",
      createNewMainCustomer_noPyramidImportValue: false,
      createNewMainCustomer_country: "none",
    });
  };

  decodeHTMLEntities = (text) => {
    var entities = [
      ["amp", "&"],
      ["apos", "'"],
      ["#x27", "'"],
      ["#x2F", "/"],
      ["#39", "'"],
      ["#47", "/"],
      ["lt", "<"],
      ["gt", ">"],
      ["nbsp", " "],
      ["quot", '"'],
    ];

    for (var i = 0, max = entities.length; i < max; ++i)
      text = text.replace(
        new RegExp("&" + entities[i][0] + ";", "g"),
        entities[i][1]
      );

    return text;
  };

  componentDidMount() {
    this.setState({
      mainCustomerItems: this.props.location.state.mainCustomerItems,
    });
  }

  render() {
    let {
      saveButtonIsLoading,
      checkoutIsLoading,
      acceptPendingUserIsLoading,
      acceptPendingUserPromptIsOpen,
      addCustomDeliveryAddressPromptIsOpen,
      addCustomDeliveryAddressButtonIsEnabled,
      createNewMainCustomerModalIsOpen,
      editMainCustomerModalIsOpen,
      isEditingMainCustomerItem_name,
      isEditingMainCustomerItem_customerNumber,
      isEditingMainCustomerItem_discount,
      isEditingMainCustomerItem_noPyramidImport,
      pageIsLoading,
      addCustomDeliveryAddressDeliveryIdInput,
      delivery_address_options,
      first_name,
      last_name,
      main_customer_id,
      delivery_address_id,
      shipping_first_name,
      shipping_last_name,
      shipping_address_1,
      shipping_address_2,
      shipping_postcode,
      shipping_city,
      shipping_country,
      mainCustomerCountry,
      createNewMainCustomer_nameValue,
      createNewMainCustomer_customerNumberValue,
      createNewMainCustomer_discountValue,
      createNewMainCustomer_noPyramidImportValue,
      createNewMainCustomer_country,
    } = this.state;

    var user_company = "";
    var user_phone = "";

    let hideChosenDeliveryAddressInfoText = true;
    let mainCustomerItemName = "";

    let userObject = this.props.location.state.userObjectItem;
    let userId = userObject.ID;
    const userInProgressRole = userObject.hasOwnProperty('user_roles') ? Object.values(userObject.user_roles).includes('in_progress') : false;
    const userInPendingRole = userObject.hasOwnProperty('user_roles') ? Object.values(userObject.user_roles).includes('pending_user') : false;
    let userIsPendingTitleAddon = "";

    if (userInProgressRole) {
      userIsPendingTitleAddon =
        " (" +
        this.props.translation("orderadmin_exports_awaiting_acceptance") +
        ")";
    }

    let userCustomDeliveryAddress = userObject.user_custom_delivery_address;
    var mainCustomerItems = [];
    if (this.state.mainCustomerItems.length < 1) {
      mainCustomerItems = this.props.location.state.mainCustomerItems;
    } else {
      mainCustomerItems = this.state.mainCustomerItems;
    }
    let userSelectedMainCustomerId = userObject.main_customer_id;

    let mainCustomersOptions = mainCustomerItems.map(
      (mainCustomerItem, index) => ({
        key: mainCustomerItem.id,
        text: this.decodeHTMLEntities(mainCustomerItem.name),
        value: mainCustomerItem.id,
      })
    );

    let countryOptions = [];
    let mainCustomerCountryOptions = [];
    let wcCountriesObject = this.props.location.state.wcCountriesObject;
    mainCustomerCountryOptions.push({
      key: "none",
      text: "- Inget land valt -",
      value: "none",
    });

    Object.keys(wcCountriesObject).forEach((countryItemKey) => {
      countryOptions.push({
        key: countryItemKey,
        text: wcCountriesObject[countryItemKey],
        value: countryItemKey,
      });
      mainCustomerCountryOptions.push({
        key: wcCountriesObject[countryItemKey],
        text: wcCountriesObject[countryItemKey],
        value: wcCountriesObject[countryItemKey],
      });
    });

    if (first_name === "") {
      first_name = userObject.first_name;
    }
    if (last_name === "") {
      last_name = userObject.last_name;
    }
    if (main_customer_id === "") {
      main_customer_id = parseInt(userSelectedMainCustomerId);
    }
    if (delivery_address_id === "") {
      delivery_address_id = parseInt(
        userObject.selected_delivery_address_post_id
      );
    }
    if (user_company === "") {
      user_company = userObject.user_company;
    }
    if (user_phone === "") {
      user_phone = userObject.user_phone;
    }

    if (user_company === "") {
      user_company = "-";
    }
    if (user_phone === "") {
      user_phone = "-";
    }

    let selectedDeliveryAddressObject;
    let selectedDeliveryAddressInfo_street = "";
    let selectedDeliveryAddressInfo_street2 = "";
    let selectedDeliveryAddressInfo_zipCode = "";
    let selectedDeliveryAddressInfo_city = "";
    let selectedDeliveryAddressInfo_country = "";
    let selectedDeliveryAddressInfo_deliveryId = "";

    let mainCustomerItem = mainCustomerItems.find((mainCustomerItem) => {
      return mainCustomerItem.id === main_customer_id;
    });

    if (mainCustomerItem !== undefined) {
      let deliveryAddressOptions = mainCustomerItem.delivery_adresses.map(
        (deliveryAddressItem, index) => ({
          key: deliveryAddressItem.id,
          text: this.decodeHTMLEntities(
            "#" +
              deliveryAddressItem.delivery_address_id +
              " - " +
              deliveryAddressItem.title
          ),
          value: deliveryAddressItem.id,
        })
      );
      mainCustomerItemName = mainCustomerItem.name;

      selectedDeliveryAddressObject = mainCustomerItem.delivery_adresses.find(
        (deliveryAddressItem) => {
          return deliveryAddressItem.id === parseInt(delivery_address_id);
        }
      );

      if (delivery_address_options.length < 1) {
        delivery_address_options = deliveryAddressOptions;
      }

      if (selectedDeliveryAddressObject) {
        selectedDeliveryAddressInfo_street =
          selectedDeliveryAddressObject.adress_info.street;
        selectedDeliveryAddressInfo_street2 =
          selectedDeliveryAddressObject.adress_info.street_2;
        selectedDeliveryAddressInfo_zipCode =
          selectedDeliveryAddressObject.adress_info.zip_code;
        selectedDeliveryAddressInfo_city =
          selectedDeliveryAddressObject.adress_info.postal_area;
        selectedDeliveryAddressInfo_country =
          selectedDeliveryAddressObject.adress_info.country;
        selectedDeliveryAddressInfo_deliveryId =
          selectedDeliveryAddressObject.adress_info.delivery_address_id;

        const foundMatch = delivery_address_options.find(
          (deliveryAddressOption) => {
            return (
              deliveryAddressOption.key === selectedDeliveryAddressObject.id
            );
          }
        );
        if (foundMatch) {
          hideChosenDeliveryAddressInfoText = false;
        }
      }

      if (main_customer_id === "") {
        main_customer_id = mainCustomerItem.id;
      }
    }

    if (shipping_first_name === "" || shipping_first_name == null) {
      shipping_first_name = userCustomDeliveryAddress.shipping_first_name;
    }
    if (shipping_last_name === "" || shipping_last_name == null) {
      shipping_last_name = userCustomDeliveryAddress.shipping_last_name;
    }
    if (shipping_address_1 === "") {
      shipping_address_1 = userCustomDeliveryAddress.shipping_address_1;
    }
    if (shipping_address_2 === "") {
      shipping_address_2 = userCustomDeliveryAddress.shipping_address_2;
    }
    if (shipping_postcode === "") {
      shipping_postcode = userCustomDeliveryAddress.shipping_postcode;
    }
    if (shipping_city === "") {
      shipping_city = userCustomDeliveryAddress.shipping_city;
    }
    if (shipping_country === "") {
      shipping_country = userCustomDeliveryAddress.shipping_country;
    }

    if (
      shipping_address_1 !== "" &&
      shipping_postcode !== "" &&
      shipping_city !== "" &&
      addCustomDeliveryAddressDeliveryIdInput !== "" &&
      this.state.main_customer_id !== ""
    ) {
      addCustomDeliveryAddressButtonIsEnabled = true;
    }

    let readyToSubmit = true;
    const foundSelectedDeliveryOption = delivery_address_options.find(
      (deliveryItem) => {
        return deliveryItem.key === delivery_address_id;
      }
    );
    if (!foundSelectedDeliveryOption) {
      readyToSubmit = false;
      delivery_address_id = 0;
    }

    if (shipping_first_name == null) {
      shipping_first_name = "";
    }
    if (shipping_last_name == null) {
      shipping_last_name = "";
    }
    if (shipping_address_1 == null) {
      shipping_address_1 = "";
    }
    if (shipping_address_2 == null) {
      shipping_address_2 = "";
    }
    if (shipping_postcode == null) {
      shipping_postcode = "";
    }
    if (shipping_city == null) {
      shipping_city = "";
    }
    if (shipping_country == null) {
      shipping_country = "";
    }

    let dataSaveObject = {
      main_customer_id,
      delivery_address_id,
      shipping_first_name,
      shipping_last_name,
      shipping_address_1,
      shipping_address_2,
      shipping_postcode,
      shipping_city,
      shipping_country,
      userId,
    };

    let deliveryAddressPostObject = {
      title:
        userCustomDeliveryAddress.shipping_address_1 +
        ", " +
        userCustomDeliveryAddress.shipping_postcode +
        " " +
        userCustomDeliveryAddress.shipping_city,
      delivery_address_id: addCustomDeliveryAddressDeliveryIdInput,
      attn_first_name: shipping_first_name,
      attn_last_name: shipping_last_name,
      street: shipping_address_1,
      street_2: shipping_address_2,
      zip_code: shipping_postcode,
      postal_area: shipping_city,
      country: shipping_country,
      add_to_main_customer_id: main_customer_id,
    };

    let usersOwnBillingAddress = {};
    if (
      userObject !== undefined &&
      userObject.own_billing_address !== undefined
    ) {
      usersOwnBillingAddress = {
        street: userObject.own_billing_address.billing_street,
        street2: userObject.own_billing_address.billing_street_2,
        zipCode: userObject.own_billing_address.billing_zip_code,
        postalArea: userObject.own_billing_address.billing_postal_area,
        country: userObject.own_billing_address.billing_country,
        email: userObject.own_billing_address.billing_email,
        orgNum: userObject.own_billing_address.billing_org_number,
      };

      if (usersOwnBillingAddress.street === "") {
        usersOwnBillingAddress.street = "-";
      }
      if (usersOwnBillingAddress.street2 === "") {
        usersOwnBillingAddress.street2 = "-";
      }
      if (usersOwnBillingAddress.zipCode === "") {
        usersOwnBillingAddress.zipCode = "-";
      }
      if (usersOwnBillingAddress.postalArea === "") {
        usersOwnBillingAddress.postalArea = "-";
      }
      if (usersOwnBillingAddress.country === "") {
        usersOwnBillingAddress.country = "-";
      }
      if (usersOwnBillingAddress.email === "") {
        usersOwnBillingAddress.email = "-";
      }
      if (usersOwnBillingAddress.orgNum === "") {
        usersOwnBillingAddress.orgNum = "-";
      }
    } else {
      usersOwnBillingAddress = {
        street: "-",
        street2: "-",
        zipCode: "-",
        postalArea: "-",
        country: "-",
        email: "-",
        orgNum: "-",
      };
    }

    return (
      <Fragment>
        <Link to="/order-admin/users">
          &larr; {this.props.translation("orderadmin_go_back")}
        </Link>
        <div className="content">
          <div className="flex-1">
            <Header as="h2">
              {this.props.translation("orderadmin_edit_user")} #{userId}
              {userIsPendingTitleAddon}
            </Header>

            <Form
              onSubmit={(e) => this._handleSubmit(e, dataSaveObject)}
              size="large"
            >
              <Segment color="red">
                <b>
                  {this.props.translation(
                    "orderadmin_userstable_displayname_column"
                  )}
                  :{" "}
                </b>{" "}
                {this.decodeHTMLEntities(userObject.display_name)}
                <br />
                <b>
                  {this.props.translation("orderadmin_email_address")}:{" "}
                </b>{" "}
                {userObject.user_email}
                <br />
                <b>{this.props.translation("orderadmin_company")}: </b>{" "}
                {user_company}
                <br />
                <b>
                  {this.props.translation("orderadmin_phone_mobile")}:{" "}
                </b>{" "}
                {user_phone}
              </Segment>

              <Grid columns={2} stackable>
                <Grid.Column>
                  <span style={{ fontSize: "90%" }}>
                    <b>
                      {this.props.translation("orderadmin_main_customer")}{" "}
                      <span style={{ color: "red" }}>*</span>:
                    </b>
                  </span>
                  <Dropdown
                    fluid
                    required
                    placeholder={this.props.translation(
                      "orderadmin_choose_main_customer"
                    )}
                    search
                    selection
                    options={mainCustomersOptions}
                    value={main_customer_id}
                    name="main_customer_id"
                    onChange={this._handleChange}
                    style={{ width: "calc(100% - 90px)" }}
                  />

                  <Button
                    icon="add"
                    color="green"
                    style={{
                      float: "right",
                      position: "relative",
                      top: "-40px",
                    }}
                    onClick={this.addNewMainCustomerButtonPressed}
                  />
                  <Button
                    icon="pencil"
                    color="yellow"
                    style={{
                      float: "right",
                      position: "relative",
                      top: "-40px",
                    }}
                    onClick={(e) =>
                      this.editMainCustomerButtonPressed(e, mainCustomerItem)
                    }
                  />
                  <Message positive size="small">
                    <Message.Header>
                      {this.props.translation(
                        "orderadmin_users_own_delivery_address"
                      )}
                      :
                    </Message.Header>
                    <br />

                    <Fragment>
                      <b>
                        {this.props.translation(
                          "orderadmin_user_street_address"
                        )}{" "}
                        <span style={{ color: "red" }}>*</span>:
                      </b>
                      &nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;
                      <Input
                        placeholder={this.props.translation(
                          "orderadmin_user_street_address"
                        )}
                        value={shipping_address_1}
                        name="shipping_address_1"
                        onChange={this._handleChange}
                      />
                      <br />
                      <b>
                        {this.props.translation(
                          "orderadmin_user_street_address_2"
                        )}
                        :
                      </b>
                      &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;
                      <Input
                        placeholder={this.props.translation(
                          "orderadmin_user_street_address_2"
                        )}
                        value={shipping_address_2}
                        name="shipping_address_2"
                        onChange={this._handleChange}
                      />
                      <br />
                      <b>
                        {this.props.translation("orderadmin_user_zip_code")}:{" "}
                        <span style={{ color: "red" }}>*</span>
                      </b>
                      &nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                      <Input
                        placeholder={this.props.translation(
                          "orderadmin_user_zip_code"
                        )}
                        value={shipping_postcode}
                        name="shipping_postcode"
                        onChange={this._handleChange}
                      />
                      <br />
                      <b>
                        {this.props.translation("orderadmin_user_city")}{" "}
                        <span style={{ color: "red" }}>*</span>:
                      </b>
                      &nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;
                      <Input
                        placeholder={this.props.translation(
                          "orderadmin_user_city"
                        )}
                        value={shipping_city}
                        name="shipping_city"
                        onChange={this._handleChange}
                      />
                      <br />
                      <b>{this.props.translation("orderadmin_country")}:</b>
                      &nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;
                      <Dropdown
                        placeholder="Välj land"
                        selection
                        options={countryOptions}
                        value={shipping_country}
                        name="shipping_country"
                        onChange={this._handleChange}
                      />
                    </Fragment>

                    <Segment>
                      <b>
                        {this.props.translation(
                          "orderadmin_delivery_address_id"
                        )}{" "}
                        <span style={{ color: "red" }}>*</span>
                        :&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </b>
                      <Input
                        placeholder={this.props.translation(
                          "orderadmin_delivery_address_id"
                        )}
                        name="addCustomDeliveryAddressDeliveryIdInput"
                        value={addCustomDeliveryAddressDeliveryIdInput}
                        onChange={this._handleChange}
                      />
                      <br />
                      <br />
                      <Button
                        onClick={(e) =>
                          this.addCustomDeliveryAddressToMainCustomer(
                            e,
                            userCustomDeliveryAddress
                          )
                        }
                        disabled={!addCustomDeliveryAddressButtonIsEnabled}
                      >
                        {this.props.translation(
                          "orderadmin_add_as_new_delivery_address_on_the_selected_main_customer"
                        )}
                      </Button>
                    </Segment>
                  </Message>

                  <Modal
                    open={addCustomDeliveryAddressPromptIsOpen}
                    basic
                    size="small"
                  >
                    <Header
                      icon="warning circle"
                      content="Lägg till som leveransaddress"
                    />
                    <Modal.Content>
                      <p>
                        Vill du verkligen lägga till användarens egna
                        leveransadress som en leveransadress kopplad på den
                        valda huvudkunden ({mainCustomerItemName})?
                      </p>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        basic
                        color="red"
                        inverted
                        onClick={() =>
                          this.closeModalWindow(
                            "add-as-delivery-address",
                            false
                          )
                        }
                      >
                        <Icon name="remove" /> Nej
                      </Button>
                      <Button
                        color="green"
                        inverted
                        onClick={() =>
                          this.closeModalWindow(
                            "add-as-delivery-address",
                            true,
                            {
                              postObject: deliveryAddressPostObject,
                              mainCustomerId: main_customer_id,
                            }
                          )
                        }
                      >
                        <Icon name="checkmark" /> Ja
                      </Button>
                    </Modal.Actions>
                  </Modal>

                  <Message positive size="small">
                    <Message.Header>
                      {this.props.translation(
                        "orderadmin_users_own_billing_address"
                      )}
                      :
                    </Message.Header>
                    <br />
                    <b>
                      {this.props.translation("orderadmin_user_street_address")}
                      :
                    </b>{" "}
                    {usersOwnBillingAddress.street}
                    <br />
                    <b>
                      {this.props.translation(
                        "orderadmin_user_street_address_2"
                      )}
                      :
                    </b>{" "}
                    {usersOwnBillingAddress.street2}
                    <br />
                    <b>
                      {this.props.translation("orderadmin_user_zip_code")}:
                    </b>{" "}
                    {usersOwnBillingAddress.zipCode}
                    <br />
                    <b>
                      {this.props.translation("orderadmin_user_city")}:
                    </b>{" "}
                    {usersOwnBillingAddress.postalArea}
                    <br />
                    <b>{this.props.translation("orderadmin_country")}:</b>{" "}
                    {usersOwnBillingAddress.country}
                    <br />
                    <b>
                      {this.props.translation("orderadmin_billing_email")}:
                    </b>{" "}
                    {usersOwnBillingAddress.email}
                    <br />
                    <b>
                      {this.props.translation("orderadmin_billing_vat_number")}:
                    </b>{" "}
                    {usersOwnBillingAddress.orgNum}
                    <br />
                  </Message>
                </Grid.Column>
                <Grid.Column>
                  <span style={{ fontSize: "90%" }}>
                    <b>
                      {this.props.translation(
                        "orderadmin_pre_selected_delivery_address"
                      )}{" "}
                      <span style={{ color: "red" }}>*</span>:
                    </b>
                  </span>
                  <Dropdown
                    fluid
                    required
                    placeholder={this.props.translation(
                      "orderadmin_choose_delivery_address"
                    )}
                    selection
                    options={delivery_address_options}
                    value={delivery_address_id}
                    name="delivery_address_id"
                    onChange={this._handleChange}
                  />
                  <Message positive size="small">
                    <Message.Header>
                      {this.props.translation(
                        "orderadmin_pre_selected_delivery_address"
                      )}
                      :
                    </Message.Header>
                    <br />
                    {!hideChosenDeliveryAddressInfoText && (
                      <Fragment>
                        <b>
                          {this.props.translation(
                            "orderadmin_user_street_address"
                          )}
                          :
                        </b>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {selectedDeliveryAddressInfo_street}
                        <br />
                        <br />
                        <b>
                          {this.props.translation(
                            "orderadmin_user_street_address_2"
                          )}
                          :
                        </b>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {selectedDeliveryAddressInfo_street2}
                        <br />
                        <br />
                        <b>
                          {this.props.translation("orderadmin_user_zip_code")}:
                        </b>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {selectedDeliveryAddressInfo_zipCode}
                        <br />
                        <br />
                        <b>{this.props.translation("orderadmin_user_city")}:</b>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {selectedDeliveryAddressInfo_city}
                        <br />
                        <br />
                        <b>{this.props.translation("orderadmin_country")}:</b>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {selectedDeliveryAddressInfo_country}
                        <br />
                        <br />
                        <b>
                          {this.props.translation(
                            "orderadmin_delivery_address_id"
                          )}
                          :
                        </b>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {selectedDeliveryAddressInfo_deliveryId}
                        <br />
                      </Fragment>
                    )}
                  </Message>
                </Grid.Column>
              </Grid>
              <br />
              <Button.Group floated="right">
                {userInProgressRole && (
                  <Fragment>
                    <Button
                      icon="check"
                      color="green"
                      size="large"
                      floated="right"
                      content={this.props.translation(
                        "orderadmin_save_and_accept_user"
                      )}
                      onClick={this._acceptPendingUser}
                      style={{ marginRight: "10px" }}
                      loading={acceptPendingUserIsLoading}
                    />

                    <Modal
                      open={acceptPendingUserPromptIsOpen}
                      basic
                      size="small"
                    >
                      <Header
                        icon="warning circle"
                        content="Bekräfta godkännande av användare"
                      />
                      <Modal.Content>
                        <p>Är du säker på att du vill godkänna användaren?</p>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button
                          basic
                          color="red"
                          inverted
                          onClick={() =>
                            this.closeModalWindow("accept-user", false)
                          }
                        >
                          <Icon name="remove" /> Nej
                        </Button>
                        <Button
                          color="green"
                          inverted
                          onClick={() =>
                            this.closeModalWindow(
                              "accept-user",
                              true,
                              dataSaveObject
                            )
                          }
                        >
                          <Icon name="checkmark" /> Ja
                        </Button>
                      </Modal.Actions>
                    </Modal>
                  </Fragment>
                )}
                {userInPendingRole && (
                  <Form.Button
                    onClick={(e) => this._onCheckOut(e, dataSaveObject)}
                    icon="calendar check"
                    content={'Check out'}
                    size="large"
                    color="green"
                    style={{ marginRight: "10px" }}
                    loading={checkoutIsLoading}
                  />
                )}
                <Form.Button
                  disabled={!readyToSubmit}
                  icon="save"
                  content={this.props.translation("orderadmin_save")}
                  size="large"
                  color="blue"
                  loading={saveButtonIsLoading}
                />
              </Button.Group>

              <Modal
                open={editMainCustomerModalIsOpen}
                closeOnDimmerClick={false}
              >
                <Modal.Header>
                  {this.props.translation("orderadmin_edit_main_customer")}
                </Modal.Header>
                <Modal.Content>
                  <b>{this.props.translation("orderadmin_edit_name")}:</b>
                  <Input
                    fluid
                    name="isEditingMainCustomerItem_name"
                    value={this.decodeHTMLEntities(
                      isEditingMainCustomerItem_name
                    )}
                    onChange={this._handleChange}
                  />

                  <br />
                  <b>
                    {this.props.translation("orderadmin_main_customer_number")}:
                  </b>
                  <Input
                    fluid
                    name="isEditingMainCustomerItem_customerNumber"
                    value={isEditingMainCustomerItem_customerNumber}
                    onChange={this._handleChange}
                  />

                  <br />
                  <b>{this.props.translation("orderadmin_country")}:</b>
                  <br />
                  <Dropdown
                    placeholder="Välj land"
                    selection
                    fluid
                    options={mainCustomerCountryOptions}
                    value={mainCustomerCountry}
                    name="mainCustomerCountry"
                    onChange={this._handleChange}
                  />
                  <br />
                  <b>
                    {this.props.translation(
                      "orderadmin_main_customer_discount"
                    )}{" "}
                    (%):
                  </b>
                  <Input
                    fluid
                    name="isEditingMainCustomerItem_discount"
                    value={isEditingMainCustomerItem_discount | "0"}
                    onChange={this._handleChange}
                  />

                  <br />
                  <Checkbox
                    label={this.props.translation(
                      "orderadmin_should_not_be_imported_to_pyramid"
                    )}
                    checked={isEditingMainCustomerItem_noPyramidImport}
                    onChange={() =>
                      this.setState({
                        isEditingMainCustomerItem_noPyramidImport: !isEditingMainCustomerItem_noPyramidImport,
                      })
                    }
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    onClick={() =>
                      this.closeEditMainCustomerButtonPressed(false)
                    }
                  >
                    <Icon name="close" />
                    {this.props.translation("orderadmin_cancel")}
                  </Button>
                  <Button
                    onClick={() =>
                      this.closeEditMainCustomerButtonPressed(true)
                    }
                    positive
                    labelPosition="left"
                    icon="checkmark"
                    content={this.props.translation("orderadmin_save")}
                    disabled={
                      !(
                        isEditingMainCustomerItem_name !== "" &&
                        isEditingMainCustomerItem_customerNumber !== ""
                      )
                    }
                  />
                </Modal.Actions>
              </Modal>

              <Modal
                open={createNewMainCustomerModalIsOpen}
                closeOnDimmerClick={false}
              >
                <Modal.Header>
                  {this.props.translation(
                    "orderadmin_create_new_main_customer"
                  )}
                </Modal.Header>
                <Modal.Content>
                  <b>{this.props.translation("orderadmin_edit_name")}:</b>
                  <Input
                    fluid
                    name="createNewMainCustomer_nameValue"
                    value={createNewMainCustomer_nameValue}
                    onChange={this._handleChange}
                  />

                  <br />
                  <b>
                    {this.props.translation("orderadmin_main_customer_number")}:
                  </b>
                  <Input
                    fluid
                    name="createNewMainCustomer_customerNumberValue"
                    value={createNewMainCustomer_customerNumberValue}
                    onChange={this._handleChange}
                  />

                  <br />

                  <b>{this.props.translation("orderadmin_country")}:</b>
                  <br />
                  <Dropdown
                    placeholder="Välj land"
                    selection
                    fluid
                    options={mainCustomerCountryOptions}
                    value={createNewMainCustomer_country}
                    name="createNewMainCustomer_country"
                    onChange={this._handleChange}
                  />
                  <br />

                  <b>
                    {this.props.translation(
                      "orderadmin_main_customer_discount"
                    )}{" "}
                    (%):
                  </b>
                  <Input
                    fluid
                    name="createNewMainCustomer_discountValue"
                    value={createNewMainCustomer_discountValue | "0"}
                    onChange={this._handleChange}
                  />

                  <br />
                  <Checkbox
                    label={this.props.translation(
                      "orderadmin_should_not_be_imported_to_pyramid"
                    )}
                    checked={createNewMainCustomer_noPyramidImportValue}
                    onChange={() =>
                      this.setState({
                        createNewMainCustomer_noPyramidImportValue: !createNewMainCustomer_noPyramidImportValue,
                      })
                    }
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button onClick={() => this.closeMainCustomerModal(false)}>
                    <Icon name="close" />
                    {this.props.translation("orderadmin_cancel")}
                  </Button>
                  <Button
                    onClick={() => this.closeMainCustomerModal(true)}
                    positive
                    labelPosition="left"
                    icon="checkmark"
                    content="Skapa"
                    disabled={
                      !(
                        createNewMainCustomer_nameValue !== "" &&
                        createNewMainCustomer_customerNumberValue !== ""
                      )
                    }
                  />
                </Modal.Actions>
              </Modal>
            </Form>
          </div>
          <Dimmer active={pageIsLoading} inverted style={{ zIndex: "1" }}>
            <Loader />
          </Dimmer>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  translation: getTranslate(state.localize),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ orderAdminUpdateUser }, dispatch);
};

OrderAdminUserDetailsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderAdminUserDetailsPage);
OrderAdminUserDetailsPage = withRouter(OrderAdminUserDetailsPage);

export default OrderAdminUserDetailsPage;

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Form } from 'semantic-ui-react';
import { storeSelectedProduct } from '../../actions/wccd-content';

import config from '../../config/config';

import axios from 'axios';

class MenuSearchBox extends Component {
    constructor(props) {
		super(props);
        this.state = {
            searchValue: "",
            isLoading: false
        }
    }

    handleFormSubmit = () => {
        const searchValue = this.state.searchValue;
        if (searchValue.length > 1) { 
            this.setState({
                isLoading: true
            });

            const postData = {
                term: searchValue,
                lang_code: this.props.selectedLanguageCode
            };
            axios.post(config.API_ENDPOINTS.SEARCH_PRODUCTS, postData)
            .then(res => {
                this.setState({
                    searchValue: "",
                    isLoading: false
                });
                this.props.history.push({
                    pathname: '/search-results',
                    state: {
                        searchResults: res.data,
                        searchTerm: searchValue,
                        wccdObject: this.props.wccdObject
                    }
                })
            });
        }
    }

    handleInputChange = (e) => {
        this.setState({
            searchValue: e.target.value
        });
    }

    render() {
        return (
        <Grid>
            <Grid.Column width={this.props.colWidth}>

            <Form onSubmit={this.handleFormSubmit}>
                <Form.Input
                    loading={this.state.isLoading}
                    action={{ icon: 'search' }} 
                    placeholder={this.props.doTranslate('search_articles') + '...'}
                    style={{marginTop: this.props.marginTopValue, marginLeft: this.props.marginLeftValue, marginRight: this.props.marginRightValue, width: this.props.searchBoxWidth, zIndex: "100"}}
                    value={this.state.searchValue}
                    onChange={this.handleInputChange}
                />
            </Form>
            </Grid.Column>
        </Grid>
        );
    }
};

const mapStateToProps = (state) => ({
    categoriesArray: state.wccd.categoriesArray,
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ storeSelectedProduct }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuSearchBox);
import React, { Fragment } from 'react';
import { Menu, Icon, Popup } from 'semantic-ui-react';
import { Translate } from 'react-localize-redux';

const CartButton = (props) => {
	const itemToRender = (
		<Popup
			trigger={
				<span onClick={props.toogleModal}>
					<Icon name="in cart" />
					<Translate id="cart" />
				</span>
			}
			content={
				<Fragment>
					{props.state.noOfProducts} <Translate id="popup_no" /> {props.state.addedProduct}{' '}
					<Translate id="popup_no_added" />
				</Fragment>
			}
			inverted
			open={props.state.newProductPopup || props.state.fading}
			className={`transition fade ${props.state.newProductPopup ? 'in custom-popup' : 'out custom-popup'}`}
			position="bottom right"
		/>
	);

	if (props.position !== '') {
		return <Menu.Item position="right">{itemToRender}</Menu.Item>;
	} else {
		return <Menu.Item fitted="horizontally">{itemToRender}</Menu.Item>;
	}
};

export default CartButton;
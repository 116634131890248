import React, { Fragment } from "react";
import { Dropdown } from "semantic-ui-react";
import { Translate } from "react-localize-redux";

const ProductsItem = (props) => {
  let selectedProductName = "";
  if (props.selectedProduct.hasOwnProperty("name")) {
    selectedProductName = JSON.parse(props.selectedProduct.name)[
      props.selectedLanguageCode
    ];
  } else {
    selectedProductName = props.doTranslate("not_selected");
  }

  return (
    <Fragment>
      <Dropdown
        item
        simple={props.simple}
        fitted="vertically"
        trigger={
          <span>
            <Translate id="spare_parts" />
          </span>
        }
        className="top-menu-item"
      >
        <Dropdown.Menu>
          <Dropdown.Item
            icon="box"
            text={props.doTranslate("all_products")}
            onClick={() => props.gotoPage("/products")}
          />
          <Dropdown.Item
            icon="box"
            text={
              props.doTranslate("choose_products") +
              ": " +
              selectedProductName +
              ""
            }
            onClick={() => props.gotoSelectedProduct()}
          />
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default ProductsItem;

const sortList = (props) => {
	var sortOrder = 1;
	if (props[0] === '-') {
		sortOrder = -1;
		props = props.substr(1);
	}
	return function(a, b) {
		var result = a[props] < b[props] ? -1 : a[props] > b[props] ? 1 : 0;
		return result * sortOrder;
	};
};

export default sortList;

import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withLocalize, getTranslate } from 'react-localize-redux';
import { Form, Grid, Button, Message, Transition, Table, Icon } from 'semantic-ui-react';
import axios from 'axios';
import md5 from 'md5';

import config from '../config/config';
import { updateUser, batchUpdateUser, refreshUser } from '../actions/user-action';
import { setError } from '../actions/settings-action';

class UserPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			saved: true,
			visible: false,
			saveButtonLoading: false,
			firstname: props.user.firstname,
			lastname: props.user.lastname,
			email: props.user.email,
			company: '',
			mainCompany: props.user.mainCompany,
			mainCustomerSystemId: props.user.mainCustomerSystemId,
			discount: props.user.discount,
			deliveryAddresses: props.user.deliveryAddresses,
			deliveryAdress: props.user.delivery,
			choosenDeliveryAddressId: props.user.delivery.id,
			phone: props.user.phone,
			billing: {
				billing_first_name: props.user.billing.billing_first_name,
				billing_last_name: props.user.billing.billing_last_name,
				billing_phone: props.user.billing.billing_phone,
				billing_address_1: props.user.billing.billing_address_1,
				billing_address_2: props.user.billing.billing_address_2,
				billing_postcode: props.user.billing.billing_postcode,
				billing_city: props.user.billing.billing_city,
				billing_country: props.user.billing.billing_country,
				billing_email: props.user.billing.billing_email
			}
		};
		this._handleRefresh();
	}

	/** Private functions */

	_handleSave = (e) => {
		this.setState({ saved: true, saveButtonLoading: true });
		let data = {
			first_name: this.state.firstname,
			last_name: this.state.lastname,
			billing_first_name: this.state.billing.billing_first_name,
			billing_last_name: this.state.billing.billing_last_name,
			billing_address_1: this.state.billing.billing_address_1,
			billing_address_2: this.state.billing.billing_address_2,
			billing_postcode: this.state.billing.billing_postcode,
			billing_city: this.state.billing.billing_city,
			billing_country: this.state.billing.billing_country,
			billing_email: this.state.billing.billing_email,
			gId: this.props.user.gId,
			user_phone: this.state.phone
		};

		axios
			.put(config.API_ENDPOINTS.PUT_UPDATE_USER, data)
			.then((response) => {
				this.setState({ visible: true, saveButtonLoading: false });
				setTimeout(() => {
					this.setState({
						visible: false
					});
				}, 3000);

				// Spara till redux
				let userData = {
					first_name: this.state.firstname,
					last_name: this.state.lastname,
					billing: this.state.billing
				};
				this.props.batchUpdateUser(userData);
			})
			.catch((error) => {
				console.log('error Update User', error);
			});
	};

	_handleRefresh() {
		let data = {
			gId: this.props.user.gId
		};
		axios
			.post(config.API_ENDPOINTS.POST_USER_REFRESH, data, config.xhrConfig)
			.then((response) => {
				if (response.data.hasOwnProperty('user_id')) {
					let userProps = Object.assign({}, response.data);
					userProps.email = this.state.email;
					userProps.guest = md5(this.state.email + ':' + userProps.user_id);
					this.props.refreshUser(userProps);
					this.setState({
						deliveryAdress: userProps.selected_delivery_address,
						choosenDeliveryAddressId: userProps.selected_delivery_address.id
					});
				}
			})
			.catch((error) => {
				this.props.setError('Fel när vi försökte ladda om användarens uppgifter');
			});
	}

	_handleChange = (e, { name, value }) => {
		this.setState({ [name]: value, saved: false });
	};

	_handleBillingChange = (e, { name, value }) => {
		this.setState((prevState) => ({
			saved: false,
			billing: {
				...prevState.billing,
				[name]: value
			}
		}));
	};

	_handleBillingCountry = (e, { value }) => {
		console.log('_handleBillingCountry', { value });
		this.setState({
			saved: false,
			billing: {
				...this.state.billing,
				billing_country: value
			}
		});
	};

	_handleAddressChange = (e, { name, value }) => {
		let address = this.props.user.deliveryAddresses.find((address) => {
			return address.id === value;
		});
		this.setState({ choosenDeliveryAddressId: value, deliveryAdress: address });
	};

	_getDeliveryAddresses() {
		let deliveryAddresses = [];
		let addresses = this.props.user.deliveryAddresses;
		for (let i = 0; i < addresses.length; i++) {
			const address = addresses[i];
			let text = address.street + ', ' + address.postal_area;
			let className = '';
			if (address.id === this.state.choosenDeliveryAddressId) {
				className = 'font-bold-important';
			}

			deliveryAddresses.push({
				key: i,
				text: text,
				value: address.id,
				className: className
			});
		}
		return deliveryAddresses;
	}

	render() {
		let addresses = this._getDeliveryAddresses();
		const { deliveryAdress, visible } = this.state;
		let saved = this.state.saved ? 'grey' : 'green';
		const { translate } = this.props;
		let getIndexOfSelectedAddress = addresses.findIndex((address) => {
			return address.value === this.state.choosenDeliveryAddressId;
		});

		return (
			<Fragment>
				<div className="content">
					<div className="flex-1">
						<h2>
							{translate('my_page')}
							<Button
								floated="right"
								color={saved}
								disabled={this.state.saved}
								loading={this.state.saveButtonLoading}
								onClick={this._handleSave}
							>
								<Icon name="save outline" /> {translate('save_all_user_setting')}
							</Button>
						</h2>
						<Transition visible={visible} animation="slide down" duration={500}>
							<div>
								<Message success icon="check" header={translate('all_user_setting_saved')} />
							</div>
						</Transition>
						<br />
						<Form>
							<Grid divided="vertically" relaxed stackable>
								<Grid.Row columns={2}>
									<Grid.Column>
										<Form.Input
											label={translate('user_firstname')}
											placeholder={translate('user_firstname')}
											name="firstname"
											value={this.state.firstname}
											onChange={this._handleChange}
										/>
										<Form.Input
											label={translate('user_lastname')}
											placeholder={translate('user_lastname')}
											name="lastname"
											value={this.state.lastname}
											onChange={this._handleChange}
										/>
									</Grid.Column>
									<Grid.Column>
										<Form.Input
											label={translate('user_phone')}
											placeholder={translate('user_phone')}
											name="phone"
											value={this.state.phone}
											onChange={this._handleChange}
										/>
										<Form.Field>
											<label>
												{translate('emailaddress')}{' '}
												<span className="font-normal">
													({translate('user_email_no_change')})
												</span>
											</label>
											<input
												placeholder={translate('user_email')}
												readOnly
												disabled
												value={this.state.email}
											/>
										</Form.Field>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={2}>
									<Grid.Column>
										<Table stackable>
											<Table.Header>
												<Table.Row>
													<Table.HeaderCell colSpan="2">
														{translate('main_customer')}
													</Table.HeaderCell>
												</Table.Row>
											</Table.Header>
											<Table.Body>
												<Table.Row>
													<Table.Cell width="4">{translate('main_customer_id')}</Table.Cell>
													<Table.Cell width="12">{this.state.mainCustomerId}</Table.Cell>
												</Table.Row>
												<Table.Row>
													<Table.Cell width="4">{translate('main_customer_name')}</Table.Cell>
													<Table.Cell width="12">{this.state.mainCompany}</Table.Cell>
												</Table.Row>
											</Table.Body>
										</Table>
									</Grid.Column>
									<Grid.Column>
										<h3>{translate('view_delivery_addresses')}</h3>
										<Form.Select
											fluid
											label={translate('select_delivery_address')}
											options={addresses}
											value={addresses[getIndexOfSelectedAddress].value}
											placeholder={translate('select_delivery_address')}
											name="deliveryAddresses"
											onChange={this._handleAddressChange}
										/>
										<Form.Input
											readOnly
											label={translate('user_street')}
											name="street"
											value={deliveryAdress.street}
										/>
										<Form.Input
											readOnly
											label={translate('user_street_2')}
											name="street_2"
											value={deliveryAdress.street_2}
										/>
										<Form.Group widths="equal">
											<Form.Input
												readOnly
												label={translate('user_zip_code')}
												name="zip_code"
												value={deliveryAdress.zip_code}
											/>
											<Form.Input
												readOnly
												label={translate('user_postal_area')}
												name="postal_area"
												value={deliveryAdress.postal_area}
											/>
										</Form.Group>
										<Form.Input
											readOnly
											label={translate('country')}
											name="country"
											value={deliveryAdress.country}
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Form>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
	countries: state.wccd.countries,
	translate: getTranslate(state.localize)
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ updateUser, batchUpdateUser, refreshUser, setError }, dispatch);
};

UserPage = withLocalize(UserPage);
export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import {
  Header,
  Button,
  Divider,
  Table,
  Icon,
  Input,
  Form,
  Grid,
  Message,
  Dimmer,
  Loader,
  Pagination,
} from "semantic-ui-react";
import withRouter from "react-router/withRouter";
import {
  getOrderAdminUsers,
  orderAdminUsersSetActiveTab,
} from "../actions/user-action";
import config from "../config/config";
import { getTranslate } from "react-localize-redux";

class OrderAdminUsers extends Component {
  constructor(props) {
    super(props);
    const tabTitlesNameValues = [
      this.props.translation("orderadmin_users"),
      this.props.translation("orderadmin_awaiting_acceptance"),
    ];

    this.state = {
      customersButtonActive: true,
      pendingUsersButtonActive: false,
      activePageIndex: 1,
      tabTitlesNames: tabTitlesNameValues,
      activeUsersArray: [],
      pendingUsersArray: [],
      wcCountriesObject: {},
      mainCustomerItems: [],
      searchArrayCopy: [],
      isLoadingRefreshUsersList: false,
      searchBoxValue: "",
      searchBoxIsLoading: false,
      showLoadingScreen: false,
      activePaginationPage: 1,
    };
  }

  clickedTabbedButton = (newPageIndex, tabTitlesNames) => {
    switch (newPageIndex) {
      case 1:
        // Visa aktiverade användare
        this.setState({
          customersButtonActive: true,
          pendingUsersButtonActive: false,
          activePageIndex: 1,
        });
        break;

      case 2:
        // Visa inväntande godkännande användare
        this.setState({
          customersButtonActive: false,
          pendingUsersButtonActive: true,
          activePageIndex: 2,
          searchBoxValue: "",
        });
        break;
      default:
        break;
    }
  };

  _getUsers = () => {
    this.setState({
      showLoadingScreen: true,
    });
    const urlPath =
      config.API_ENDPOINTS.ORDER_ADMIN_GET_USERS +
      "?gId=" +
      this.props.user.gId;

    Promise.all([axios.get(urlPath)])
      .then((resp) => {
        const usersResponse = resp[0].data;

        this.setState({
          activeUsersArray: usersResponse.customers,
          pendingUsersArray: usersResponse.pending_users,
          mainCustomerItems: usersResponse.main_customers,
          wcCountriesObject: usersResponse.wc_countries,
          showLoadingScreen: false,
        });
      })
      .catch((error) => {
        console.log("error allWccd", error);
      });
  };

  _refreshUsersList = () => {
    this.setState({
      activeUsersArray: [],
      pendingUsersArray: [],
      mainCustomerItems: [],
      wcCountriesObject: {},
    });
    this._getUsers();
  };

  handleRowClick = (userItem) => {
    this.setState({
      showLoadingScreen: true,
    });

    const urlPath =
      config.API_ENDPOINTS.ORDER_ADMIN_GET_SINGLE_USER +
      "/" +
      userItem.ID +
      "?gId=" +
      this.props.user.gId;

    Promise.all([axios.get(urlPath)])
      .then((resp) => {
        const userResponse = resp[0].data;
        this.props.history.push({
          pathname: "/order-admin/user/" + userItem.ID,
          state: {
            wcCountriesObject: this.state.wcCountriesObject,
            mainCustomerItems: this.state.mainCustomerItems,
            userObjectItem: userResponse,
          },
        });
      })
      .catch((error) => {
        console.log("error allWccd", error);
      });
  };

  _handleSearchBoxInputChange = (e) => {
    if (e.target.value.length > 0) {
      this.setState({
        searchBoxValue: e.target.value,
      });
      setTimeout(() => {
        this._searchCustomersAction();
      }, 20);
    } else {
      this._closeSearching();
    }
  };

  _closeSearching = () => {
    this.setState({
      searchBoxValue: "",
      activeUsersArray: this.state.searchArrayCopy,
    });
  };

  _searchCustomersAction = () => {
    let searchValue = this.state.searchBoxValue.toLowerCase().replace("#", "");
    let searchResultsArray = [];

    if (this.state.activePaginationPage !== 1) {
      this.setState({
        activePaginationPage: 1,
      });
    }

    if (this.state.activePageIndex !== 1) {
      this.setState({
        activePageIndex: 1,
        customersButtonActive: true,
        pendingUsersButtonActive: false,
        usersArray: this.props.user.orderAdminUsers.customers,
        searchBoxIsLoading: true,
      });
      this.props.orderAdminUsersSetActiveTab(1);
    } else {
      this.setState({
        searchBoxIsLoading: true,
      });
    }

    if (this.state.searchArrayCopy.length < 1) {
      this.setState({
        searchArrayCopy: this.state.activeUsersArray,
      });
    }

    this.state.searchArrayCopy.forEach((userItem) => {
      if (
        userItem.display_name
          .toString()
          .toLowerCase()
          .includes(searchValue) ||
        userItem.ID.toString()
          .toLowerCase()
          .includes(searchValue) ||
        userItem.user_email
          .toString()
          .toLowerCase()
          .includes(searchValue) ||
        userItem.username
          .toString()
          .toLowerCase()
          .includes(searchValue) ||
        userItem.main_customer_id
          .toString()
          .toLowerCase()
          .includes(searchValue)
      ) {
        searchResultsArray.push(userItem);
      }
    });

    this.setState({
      activeUsersArray: searchResultsArray,
      searchBoxIsLoading: false,
    });
  };

  decodeHTMLEntities = (text) => {
    var entities = [
      ["amp", "&"],
      ["apos", "'"],
      ["#x27", "'"],
      ["#x2F", "/"],
      ["#39", "'"],
      ["#47", "/"],
      ["lt", "<"],
      ["gt", ">"],
      ["nbsp", " "],
      ["quot", '"'],
    ];

    for (var i = 0, max = entities.length; i < max; ++i)
      text = text.replace(
        new RegExp("&" + entities[i][0] + ";", "g"),
        entities[i][1]
      );

    return text;
  };

  handlePaginationChange = (e, { activePage }) =>
    this.setState({ activePaginationPage: activePage });

  componentDidMount() {
    this._getUsers();
  }

  render() {
    let isPendingUserTab = false;
    let titleColor = "green";
    let usersArrayValue = [];
    let activeUsersPerPage = 10;

    if (this.state.activePageIndex === 2) {
      isPendingUserTab = true;
      titleColor = "red";
    }

    if (this.state.activePageIndex === 1) {
      let userArrayStartVal = 0;
      if (this.state.activePaginationPage > 1) {
        userArrayStartVal =
          activeUsersPerPage * (this.state.activePaginationPage - 1);
      }
      usersArrayValue = this.state.activeUsersArray.slice(
        userArrayStartVal,
        userArrayStartVal + activeUsersPerPage
      );
    } else if (this.state.activePageIndex === 2) {
      usersArrayValue = this.state.pendingUsersArray;
    }

    let paginationPagesNeeded = Math.ceil(
      this.state.activeUsersArray.length / activeUsersPerPage
    );

    const tabTitlesValues = [
      this.props.translation("orderadmin_users"),
      this.props.translation("orderadmin_awaiting_acceptance"),
    ];

    let pageTitle = "";
    if (this.state.activePageIndex === 1) {
      pageTitle = tabTitlesValues[0];
    } else if (this.state.activePageIndex === 2) {
      pageTitle = tabTitlesValues[1];
    }

    return (
      <div className="content">
        <div className="flex-1">
          <Grid columns={2} stackable>
            <Grid.Column>
              <Button.Group>
                <Button
                  size="large"
                  disabled={this.state.customersButtonActive}
                  onClick={() => this.clickedTabbedButton(1, tabTitlesValues)}
                >
                  {tabTitlesValues[0]}
                </Button>
                <Button
                  size="large"
                  disabled={this.state.pendingUsersButtonActive}
                  onClick={() => this.clickedTabbedButton(2, tabTitlesValues)}
                >
                  {tabTitlesValues[1]}
                </Button>
              </Button.Group>
            </Grid.Column>

            <Grid.Column>
              <Form>
                <Input
                  action={{ icon: "search" }}
                  placeholder={`${this.props.translation(
                    "orderadmin_search_users"
                  )}...`}
                  onChange={this._handleSearchBoxInputChange}
                  value={this.state.searchBoxValue}
                  loading={this.state.searchBoxIsLoading}
                  disabled={isPendingUserTab}
                  style={{
                    float: "right",
                    position: "relative",
                    left: "-40px",
                  }}
                />
              </Form>
            </Grid.Column>
          </Grid>

          <Divider />

          {this.state.searchBoxValue !== "" && (
            <Message color="red">
              <p>
                <b>{this.props.translation("orderadmin_search_result_for")}:</b>{" "}
                {this.state.searchBoxValue}
                <Button
                  icon="close"
                  floated="right"
                  style={{ position: "relative", top: "-8px" }}
                  onClick={this._closeSearching}
                  color="red"
                />
              </p>
            </Message>
          )}

          <Button
            floated="right"
            icon
            labelPosition="left"
            onClick={this._refreshUsersList}
            loading={this.state.isLoadingRefreshUsersList}
            positive
          >
            <Icon name="refresh" />
            {this.props.translation("orderadmin_update_users_list")}
          </Button>

          <Header as="h2" color={titleColor}>
            <Icon name="users" />
            {pageTitle}
          </Header>

          {this.state.activePageIndex === 1 && (
            <p>
              {this.props.translation("orderadmin_accepted_users_description")}
            </p>
          )}

          {this.state.activePageIndex === 2 && (
            <p>
              {this.props.translation(
                "orderadmin_awaiting_acceptance_users_description"
              )}
            </p>
          )}

          <Table singleLine compact selectable>
            <Table.Header>
              {!isPendingUserTab && (
                <Table.Row>
                  <Table.HeaderCell>
                    {this.props.translation(
                      "orderadmin_userstable_userid_column"
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {this.props.translation(
                      "orderadmin_userstable_displayname_column"
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {this.props.translation(
                      "orderadmin_userstable_email_column"
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {this.props.translation(
                      "orderadmin_userstable_username_column"
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {this.props.translation(
                      "orderadmin_userstable_main_customer_id_column"
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              )}
              {isPendingUserTab && (
                <Table.Row>
                  <Table.HeaderCell>
                    {this.props.translation(
                      "orderadmin_userstable_userid_column"
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {this.props.translation(
                      "orderadmin_userstable_displayname_column"
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {this.props.translation(
                      "orderadmin_userstable_email_column"
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              )}
            </Table.Header>

            <Table.Body>
              {usersArrayValue &&
                usersArrayValue.length > 0 &&
                usersArrayValue.map((userItem, index) => {
                  if (!isPendingUserTab) {
                    return (
                      <Table.Row
                        positive
                        key={index}
                        onClick={() => this.handleRowClick(userItem)}
                        style={{ cursor: "pointer" }}
                      >
                        <Table.Cell>#{userItem.ID}</Table.Cell>
                        <Table.Cell>
                          {this.decodeHTMLEntities(userItem.display_name)}
                        </Table.Cell>
                        <Table.Cell>{userItem.user_email}</Table.Cell>
                        <Table.Cell>{userItem.username}</Table.Cell>
                        <Table.Cell>{userItem.main_customer_id}</Table.Cell>
                      </Table.Row>
                    );
                  } else if (userItem.user_role === 'pending_user') {
                    return (
                      <Table.Row
                        negative
                        key={index}
                        onClick={() => this.handleRowClick(userItem)}
                        style={{ cursor: "pointer" }}
                      >
                        <Table.Cell>#{userItem.ID}</Table.Cell>
                        <Table.Cell>
                          {this.decodeHTMLEntities(userItem.display_name)}
                        </Table.Cell>
                        <Table.Cell>{userItem.user_email}</Table.Cell>
                      </Table.Row>
                    );
                  } else if (userItem.user_role === 'in_progress') {
                    return (
                      <Table.Row
                        warning
                        key={index}
                        onClick={() => this.handleRowClick(userItem)}
                        style={{ cursor: "pointer" }}
                      >
                        <Table.Cell>#{userItem.ID}</Table.Cell>
                        <Table.Cell>
                          {this.decodeHTMLEntities(userItem.display_name)}
                        </Table.Cell>
                        <Table.Cell>{userItem.user_email}</Table.Cell>
                      </Table.Row>
                    );
                  } else {
                    return (
                      <Table.Row
                        key={index}
                        onClick={() => this.handleRowClick(userItem)}
                        style={{ cursor: "pointer" }}
                      >
                        <Table.Cell>#{userItem.ID}</Table.Cell>
                        <Table.Cell>
                          {this.decodeHTMLEntities(userItem.display_name)}
                        </Table.Cell>
                        <Table.Cell>{userItem.user_email}</Table.Cell>
                      </Table.Row>
                    );
                  }
                })}
            </Table.Body>

            {this.state.activePageIndex === 1 && (
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="5">
                    <Pagination
                      activePage={this.state.activePaginationPage}
                      onPageChange={this.handlePaginationChange}
                      totalPages={paginationPagesNeeded}
                      style={{ position: "relative", left: "0" }}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            )}
          </Table>
          <Dimmer
            active={this.state.showLoadingScreen}
            style={{ zIndex: "1" }}
            inverted
          >
            <Loader />
          </Dimmer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  translation: getTranslate(state.localize),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getOrderAdminUsers, orderAdminUsersSetActiveTab },
    dispatch
  );
};

OrderAdminUsers = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderAdminUsers);
OrderAdminUsers = withRouter(OrderAdminUsers);

export default OrderAdminUsers;

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  withLocalize,
  getTranslate,
  getActiveLanguage,
} from "react-localize-redux";

class Info extends Component {
  render() {
    const { translate, currentLanguage } = this.props;
    let manualLink = "";
    if (currentLanguage.code === "se") {
      manualLink =
        "https://spareparts.swepac.com/wp-content/uploads/document_pdf/anvandarinstruktioner_swepac_rp.pdf";
    } else {
      manualLink =
        "https://spareparts.swepac.com/wp-content/uploads/document_pdf/anvandarinstruktioner_swepac_en.pdf";
    }
    return (
      <Fragment>
        <div className="wccd-hero-image" />

        <div className="content" style={{ marginTop: 210 }}>
          <div className="flex-1">
            <h1 className="text-align-center">Information</h1>
            <p style={{ fontSize: "1.2em" }}>
              {translate("contact_us_on_phone_num")}:{" "}
              <strong>0372-156 05</strong>.<br />
              {translate("or_contact_us_by_email")}: info@swepac.com
              <br />
              <br />
              <a href={manualLink} target="_blank" rel="noopener noreferrer">
                {translate("user_instructions_file_download_link_text")}
              </a>
            </p>
            <p>
              &copy; Swepac Spareparts Portal v.{this.props.settings.version}
            </p>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  translate: getTranslate(state.localize),
  currentLanguage: getActiveLanguage(state.localize),
  settings: state.settings,
});

Info = withLocalize(Info);
export default connect(mapStateToProps)(Info);

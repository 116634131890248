import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getTranslate, Translate } from "react-localize-redux";
import {
  Grid,
  Dropdown,
  Button,
  Modal,
  Icon,
  Header,
  Container,
} from "semantic-ui-react";

import config from "../config/config";
import axios from "axios";

import CartSummery from "../components/cart-summery";
import {
  getOrderAdminOrders,
  orderAdminUpdateOrderObject,
} from "../actions/user-action";
import CurrencyItem from "./menu/currency-item";
import { updateActiveCurrency } from "../actions/wccd-content";

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownValue: "",
      saveButtonIsLoading: false,
      saveButtonWarningIsOpen: false,
    };
  }

  _getSentOrder = () => {
    if (this.props.orderSent !== "") {
      return JSON.parse(this.props.orderSent);
    } else {
      return null;
    }
  };

  closeModalWindow = (isPermittedToSetOrderToExportedValue) => {
    this.setState({ saveButtonWarningIsOpen: false });

    if (isPermittedToSetOrderToExportedValue === true) {
      this.handleSaveDropdownButtonClick(isPermittedToSetOrderToExportedValue);
    }
  };

  handleDropdownChange = (e, { value }) =>
    this.setState({ dropdownValue: value });

  handleSaveDropdownButtonClick = (isPermittedToSetOrderToExported) => {
    let newStatus = this.state.dropdownValue;
    let shouldChangeStatus = false;
    let redirectToOrdersList = false;
    if (newStatus !== "") {
      if (newStatus !== "exported" && !isPermittedToSetOrderToExported) {
        shouldChangeStatus = true;
      } else if (newStatus === "exported" && isPermittedToSetOrderToExported) {
        shouldChangeStatus = true;
        redirectToOrdersList = true;
      } else {
        shouldChangeStatus = false;
      }

      if (shouldChangeStatus) {
        let orderId = this.props.orderIdValue;
        let requestUrlPath =
          config.API_ENDPOINTS.ORDER_ADMIN_UPDATE_ORDER + orderId;
        let data = {
          gId: this.props.user.gId,
          newStatus: newStatus,
        };

        this.setState({ saveButtonIsLoading: true });

        if (redirectToOrdersList) {
          this.props.historyObject.push("/order-admin/orders");
        }

        axios
          .put(requestUrlPath, data)
          .then((response) => {
            this.props.orderAdminUpdateOrderObject({
              orderId: orderId,
              newStatus: newStatus,
              shouldDelete: redirectToOrdersList,
            });
            if (!redirectToOrdersList) {
              this.setState({
                saveButtonIsLoading: false,
                saveButtonWarningIsOpen: false,
              });
            }
          })
          .catch((error) => {
            console.log("error Updating Order", error);
          });
      } else {
        this.setState({ saveButtonWarningIsOpen: true });
      }
    }
  };

  _handleCurrencySelect = (e, { value }) => {
    this.props.updateActiveCurrency(value);
  };

  _getCurrencies(currencies) {
    let currenciesToSelect = currencies.map((currency) => {
      return {
        key: currency.key,
        value: currency.key,
        text: <Fragment>{currency.name}</Fragment>,
      };
    });
    return currenciesToSelect;
  };

  render() {
    const sentOrder = this._getSentOrder();
    const has = Object.prototype.hasOwnProperty;
    let user = {};
    let orderArticlesArray = [];
    let isOrderAdminView = false;
    let orderStatus = "";
    let orderCannotBeExported = false;

    const currenciesToSelect = this.props.currencies.length > 0 ? this._getCurrencies(this.props.currencies) : [];
    const currentCurrencyId = this.props.currentCurrency ? this.props.currentCurrency : "sek";

    let orderStatusOptions = [
      {
        key: "in_progress",
        value: "in_progress",
        text: 'In Progress',
      },
      {
        key: "new",
        value: "new",
        text: this.props.translation("orderadmin_exports_not_exported"),
      },
      {
        key: "pending",
        value: "pending",
        text: this.props.translation("orderadmin_exports_awaiting_acceptance"),
      },
      {
        key: "exported_manually",
        value: "exported_manually",
        text: this.props.translation("orderadmin_exports_manually_exported"),
      },
      {
        key: "await_export",
        value: "await_export",
        text: this.props.translation("orderadmin_exports_hold_off_export"),
      },
      {
        key: "exported",
        value: "exported",
        text: this.props.translation("orderadmin_exports_have_been_exported"),
      },
    ];
    let mainCustomerIdValue = "";
    let mainCustomerName = "";

    if (sentOrder !== null) {
      user.customerOrderNum = sentOrder._customer_order_num;
      user.firstname = sentOrder._ref_first_name;
      user.lastname = sentOrder._ref_last_name;
      let billing = {
        billing_phone: sentOrder._ref_phone,
        email: has.call(sentOrder, 'ref_email') ? sentOrder.ref_email : ''
      };
      user.billing = billing;
      user.backorder_split_delivery = sentOrder._backorder_split_delivery;
      user.parcelId = sentOrder._parcel_id;
      user.notes = sentOrder._notes;
      let delivery = {
        attn_first_name: sentOrder.shipping.first_name,
        attn_last_name: sentOrder.shipping.last_name,
        attn: "",
        street: sentOrder.shipping.address_1,
        street_2: sentOrder.shipping.address_2,
        zip_code: sentOrder.shipping.postcode,
        country: sentOrder.shipping.country,
        postal_area: sentOrder.shipping.city,
      };
      user.delivery = delivery;
    } else if (this.props.viewOrderItem) {
      isOrderAdminView = true;
      let orderObject = this.props.viewOrderItem;

      if (orderObject) {
        mainCustomerIdValue = orderObject.main_customer_id;
        mainCustomerName = orderObject.main_customer_name;
        orderCannotBeExported = !orderObject.export_to_pyramid;
        user.customerOrderNum = orderObject.customer_order_num;
        user.firstname = orderObject.ref_first_name;
        user.lastname = orderObject.ref_last_name;
        let billing = {
          billing_phone: orderObject.ref_phone,
          email: has.call(orderObject, 'ref_email') ? orderObject.ref_email : ''
        };
        user.billing = billing;
        user.backorder_split_delivery = orderObject.backorder_split_delivery;
        user.parcelId = orderObject.parcel_id;
        user.notes = orderObject.notes;
        let delivery = {
          attn_first_name: orderObject.deliveryAddress_attnFirstName,
          attn_last_name: orderObject.deliveryAddress_attnLastName,
          attn: orderObject.deliveryAddress_attn,
          street: orderObject.deliveryAddress_street,
          street_2: orderObject.deliveryAddress_street2,
          zip_code: orderObject.deliveryAddress_zipCode,
          country: orderObject.deliveryAddress_country,
          postal_area: orderObject.deliveryAddress_postalArea,
        };
        user.delivery = delivery;
        orderArticlesArray = orderObject.articles;
        if (this.state.dropdownValue === "") {
          orderStatus = orderObject.order_status;
        } else {
          orderStatus = this.state.dropdownValue;
        }
      }
    } else {
      user = this.props.user;
    }

    if (user.backorder_split_delivery === "true") {
      user.backorder_split_delivery = 1;
    } else if (user.backorder_split_delivery === "false") {
      user.backorder_split_delivery = 0;
    }

    if (user.notes === "") {
      user.notes = "-";
    }
    if (user.parcelId === "") {
      user.parcelId = "-";
    }


    return (
      <Fragment>
        {!this.props.hideHeader && (
          <h3>
            <Translate id="review_order" />
          </h3>
        )}
        <Grid divided="vertically" relaxed stackable>
          <Grid.Row columns={3}>
            <Grid.Column>
              {!isOrderAdminView && (
                <strong>
                  <Translate id="your_order_id" />
                </strong>
              )}
              {isOrderAdminView && (
                <strong>
                  {this.props.translation("orderadmin_order_number")}
                </strong>
              )}
              <br />
              {user.customerOrderNum}
              <br />
              <strong>
                <Translate id="ref_name" />
              </strong>
              <br />
              {user.firstname} {user.lastname}
              <br />
              <strong>
                <Translate id="user_phone" />
              </strong>
              <br />
              {user.billing.billing_phone}
              <br />
              <strong>
                <Translate id="user_email" />
              </strong>
              <br />
              {user.billing.email}
              {isOrderAdminView && (
                <Fragment>
                  <br />
                  <strong>
                    {this.props.translation(
                      "orderadmin_ordertable_main_customer_column"
                    )}
                  </strong>
                  <br />
                  {mainCustomerIdValue}
                  <br/>
                  {mainCustomerName}
                </Fragment>
              )}
            </Grid.Column>
            <Grid.Column>
              <strong>
                <Translate id="selected_backorder" />
              </strong>
              <br />
              {user.backorder_split_delivery === 1 ? (
                <Translate id="backorder_split" />
              ) : (
                <Translate id="backorder_complete" />
              )}
              <br />
              <strong>
                <Translate id="shipping_label" />
              </strong>
              <br />
              {user.parcelId}
              <br />
              <strong>
                <Translate id="note" />
              </strong>
              <br />
              {user.notes}
            </Grid.Column>
            <Grid.Column>
              <h4>
                <Translate id="delivery_address" />
              </h4>
              {user.delivery.attn_first_name !== "" ? (
                <Fragment>
                  {user.delivery.attn_first_name} {user.delivery.attn_last_name}
                  <br />
                </Fragment>
              ) : (
                ""
              )}
              {user.delivery.street}
              <br />
              {user.delivery.street_2 !== "" ? (
                <Fragment>
                  {user.delivery.street_2}
                  <br />
                </Fragment>
              ) : (
                ""
              )}
              {user.delivery.zip_code} {user.delivery.postal_area}
              <br />
              {user.delivery.country}
              {isOrderAdminView && (
                <Fragment>
                  <br />
                  <br />
                  {!orderCannotBeExported && (
                    <Container className="no-print">
                      <strong>
                        {this.props.translation("orderadmin_export_status")}:
                      </strong>
                      &nbsp;&nbsp;
                      <br />
                      <Dropdown
                        onChange={this.handleDropdownChange}
                        options={orderStatusOptions}
                        placeholder="Välj en orderstatus"
                        selection
                        style={{ zIndex: 20 }}
                        value={orderStatus}
                      />
                      &nbsp;&nbsp;
                      <Button
                        color="blue"
                        onClick={() =>
                          this.handleSaveDropdownButtonClick(false)
                        }
                        loading={this.state.saveButtonIsLoading}
                        className="no-print"
                      >
                        <Icon name="save" />
                        {this.props.translation("orderadmin_save")}
                      </Button>
                    </Container>
                  )}
                  {orderCannotBeExported && (
                    <Fragment>
                      <strong>
                        {this.props.translation("orderadmin_export_status")}:
                      </strong>
                      &nbsp;&nbsp;
                      <br />
                      <p
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }}
                      >
                        Exporteras inte till Pyramid
                      </p>
                    </Fragment>
                  )}
                  &nbsp;&nbsp;
                  <Container className="no-print">
                    <CurrencyItem
                        selection
                        currenciesToSelect={currenciesToSelect}
                        currentCurrencyId={currentCurrencyId}
                        handleCurrencySelect={this._handleCurrencySelect}
                        simple={true}
                        style={{ zIndex: 10 }}
                    />
                  </Container>

                  <Modal
                    open={this.state.saveButtonWarningIsOpen}
                    basic
                    size="small"
                  >
                    <Header
                      icon="warning circle"
                      content="Sätt order till exporterad"
                    />
                    <Modal.Content>
                      <p>
                        Är du säker på att du vill sätta ordens status till
                        Exporterad?
                      </p>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        basic
                        color="red"
                        inverted
                        onClick={() => this.closeModalWindow(false)}
                      >
                        <Icon name="remove" /> Nej
                      </Button>
                      <Button
                        color="green"
                        inverted
                        onClick={() => this.closeModalWindow(true)}
                      >
                        <Icon name="checkmark" /> Ja
                      </Button>
                    </Modal.Actions>
                  </Modal>
                </Fragment>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <h4>
                <Translate id="products" />
              </h4>
              <CartSummery orderArticlesArray={orderArticlesArray} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.checkOutUser,
  userObject: state.user,
  orderSent: state.wccd.orderSent,
  translation: getTranslate(state.localize),
  currentCurrency: state.wccd.selectedCurrency,
  currencies: state.wccd.currencies,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getOrderAdminOrders, orderAdminUpdateOrderObject, updateActiveCurrency },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Summary);

import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Translate,
  withLocalize,
  getActiveLanguage,
  getTranslate,
} from "react-localize-redux";
import {
  Grid,
  Dimmer,
  Loader,
  Message,
  Input,
  Form,
  List,
  Image,
} from "semantic-ui-react";

import {
  storeSelectedProduct,
  isLoading,
  setSelectedProduct,
} from "../actions/wccd-content";
import ListProducts from "../components/list-products";
import config from "../config/config";
import axios from "axios";

class AllProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1,
      loadingCategories: false,
      mainProductSearchIsLoading: false,
      mainProductSearchQuery: "",
      mainProductSearchResultItems: [],
      hasSubmittedSearchForm: false,
    };
  }

  componentDidUpdate(props) {
    if (
      this.props.isLoaded &&
      !this.props.isLoadingComplete &&
      this.state.loadingCategories
    ) {
      this.setState({ loadingCategories: false });
      this.props.history.push("/category/" + this.props.selectedProduct.slug);
    }
  }

  handleAccordion = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  selectedMainProduct = (item) => {
    this.props.isLoading(true);

    if (this.props.categoriesArray.hasOwnProperty(item.slug)) {
      let product = {
        id: item.id,
        name: JSON.stringify(item.lang_name),
        slug: item.slug,
        img: item.product_catalog_image_url,
      };
      this.props.setSelectedProduct(product);
      this.props.storeSelectedProduct(item);
      this.props.history.push("/category/" + item.slug);
    } else {
      this.setState({ loadingCategories: true });
      this.props.storeSelectedProduct(item);
    }
  };

  handleMainProductSearchSubmit = () => {
    const searchTerm = this.state.mainProductSearchQuery;
    if (searchTerm.trim() !== "") {
      this.setState({
        mainProductSearchIsLoading: true,
        hasSubmittedSearchForm: true,
      });
      let requestUrlPath =
        config.API_ENDPOINTS.SEARCH_MAIN_PRODUCT_BY_SERIAL_NUMBER;
      let data = {
        term: searchTerm,
      };
      axios
        .post(requestUrlPath, data)
        .then((response) => {
          if (response.data) {
            this.setState({ mainProductSearchResultItems: response.data });
          }
          this.setState({ mainProductSearchIsLoading: false });
        })
        .catch((error) => {
          console.log("error searching for main product", error);
          this.setState({ mainProductSearchIsLoading: false });
        });
    } else {
      this.setState({
        hasSubmittedSearchForm: false,
        mainProductSearchResultItems: [],
      });
    }
  };

  handleMainProductSearchInputChanged = (e) => {
    this.setState({
      mainProductSearchQuery: e.target.value,
    });
  };

  handleMainProductSearchResultClick = (mainProductSlug) => {
    this.props.machineTypes.map((machineTypeItem) => {
      machineTypeItem.children.map((machineTypeChildItem) => {
        machineTypeChildItem.product_categories.map((productCategoryItem) => {
          if (productCategoryItem.slug === mainProductSlug) {
            this.selectedMainProduct(productCategoryItem);
          }
          return null;
        });
        return null;
      });
      return null;
    });
  };

  render() {
    const {
      activeIndex,
      loadingCategories,
      mainProductSearchIsLoading,
      hasSubmittedSearchForm,
      mainProductSearchResultItems,
    } = this.state;
    const { translate } = this.props;

    return (
      <Fragment>
        <Dimmer active={loadingCategories} page inverted>
          <Loader size="large">Loading</Loader>
        </Dimmer>
        <h2>
          <Translate id="all_products" />
        </h2>
        <Message info>
          <Message.Header>
            <Translate id="search_main_product_by_serial_number_title" />
          </Message.Header>
          <p>
            <Translate id="search_main_product_by_serial_number_description" />:
          </p>

          <Form onSubmit={this.handleMainProductSearchSubmit}>
            <Input
              action={{ icon: "search" }}
              placeholder={translate("search_input_placeholder_text")}
              onChange={this.handleMainProductSearchInputChanged}
            />
          </Form>
          <Loader active={mainProductSearchIsLoading} />

          {mainProductSearchResultItems.length > 0 && (
            <Fragment>
              <h3>
                <Translate id="search_results_title" />:
              </h3>
              <List divided verticalAlign="middle" size="big">
                {mainProductSearchResultItems.map((mainProductItem, index) => {
                  const productImageUrl =
                    "https://spareparts.swepac.com/wp-content/uploads/wccd-spare-parts-manager/product-catalog-images/final/product_catalog_image-" +
                    mainProductItem.slug +
                    ".jpg";
                  return (
                    <List.Item
                      key={index}
                      onClick={() =>
                        this.handleMainProductSearchResultClick(
                          mainProductItem.slug
                        )
                      }
                    >
                      <Image
                        size="small"
                        src={productImageUrl}
                        style={{ cursor: "pointer" }}
                      />
                      <List.Content>
                        <List.Header as="a">
                          {
                            mainProductItem.title[
                              this.props.currentLanguage.code
                            ]
                          }
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  );
                })}
              </List>
            </Fragment>
          )}
          {mainProductSearchResultItems.length < 1 &&
            hasSubmittedSearchForm &&
            !mainProductSearchIsLoading && (
              <Fragment>
                <h3>
                  <Translate id="search_results_title" />:
                </h3>
                <p>
                  <Translate id="no_search_results_text" />
                </p>
              </Fragment>
            )}
        </Message>
        {/* <Grid divided="vertically" stackable relaxed columns={this.props.machineTypes.length}> */}
        <Grid
          divided="vertically"
          stackable
          relaxed
          columns={this.props.machineTypes.length}
          style={{ marginTop: "20px" }}
        >
          {this.props.machineTypes.map((item) => {
            return (
              <Grid.Column key={item.id}>
                <div className="margin-bottom-big">
                  <h3>{item.lang_name[this.props.currentLanguage.code]}</h3>
                  <ListProducts
                    childItem={item.children}
                    selectedMainProduct={this.selectedMainProduct}
                    activeIndex={activeIndex}
                    selectedIndex={this.handleAccordion}
                    selectedLanguageCode={this.props.currentLanguage.code}
                  />
                </div>
              </Grid.Column>
            );
          })}
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  machineTypes: state.wccd.machineTypes,
  categoriesArray: state.wccd.categoriesArray,
  selectedProduct: state.wccd.selectedProduct,
  isLoaded: state.wccd.isLoaded,
  isLoadingComplete: state.wccd.isLoading,
  currentLanguage: getActiveLanguage(state.localize),
  translate: getTranslate(state.localize),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { storeSelectedProduct, isLoading, setSelectedProduct },
    dispatch
  );
};

AllProducts = withLocalize(AllProducts);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllProducts);

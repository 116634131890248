import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Segment, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

import Summary from '../components/checkout-summary';

class OrderSent extends Component {
	componentDidMount() {
		if (this.props.orderSent === '') {
			this.props.history.push('/');
		}
	}

	_printPage = () => {
		window.print();
	};

	_getDate() {
		var date = new Date().toLocaleString();
		return date.toString();
	}

	render() {
		let strDate = this._getDate();
		return (
			<Fragment>
				<div className="wccd-hero-image no-print" />
				<div className="only-print" style={{ textAlign: 'right' }}>
					{strDate}
				</div>
				<div className="text-align-center margin-bottom-big no-print" style={{ marginTop: 210 }}>
					<h1>Tack för din beställning</h1>
					<p style={{ fontSize: '1.2em' }} className="margin-bottom-big">
						Vi har nu mottagit din beställning, och vi kommer att behandla den så snart som möjligt!
					</p>
				</div>
				<div>
					<Segment>
						<Summary hideHeader={true} />
					</Segment>
					<div className="no-print">
						<Button floated="right" onClick={this._printPage}>
							<Icon name="print" /> <Translate id="print_order" />
						</Button>
						<Link to="/">
							<Button floated="left">
								<Icon name="home" /> <Translate id="back_to_main_page" />
							</Button>
						</Link>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	orderSent: state.wccd.orderSent
});

export default withRouter(connect(mapStateToProps)(OrderSent));
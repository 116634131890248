import React from "react";
import ReactDOM from "react-dom";

import "semantic-ui-css/semantic.min.css";
import "./index.css";

import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { LocalizeProvider } from "react-localize-redux";

import App from "./App";
import Home from "./container/home";
import Pages from "./container/pages";
import Posts from "./container/posts";
import AllProducts from "./container/all-products";
import Products from "./container/products";
import Checkout from "./container/checkout";
import OrderSent from "./container/order-sent";
import User from "./container/user";
import NewUser from "./container/new-user";
import NewUserSuccess from "./container/new-user-success";
import Info from "./container/info";
import SearchProducts from "./container/search-products";
import OrderAdminOrders from "./container/order-admin-orders";
import OrderAdminUsers from "./container/order-admin-users";
import CeAdminPrintList from "./container/ce-admin-print-list";
import OrderAdminOrderDetailsPage from "./container/order-admin-order-details";
import OrderAdminUserDetailsPage from "./container/order-admin-user-details";
import CeOchMotorintygSok from "./container/ce-och-motorintyg-sok";
import ClearStoragePage from "./container/clearstoragepage";

import * as serviceWorker from "./serviceWorker";

class Start extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <LocalizeProvider store={store}>
          <BrowserRouter>
            <App>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/clear" component={ClearStoragePage} />
                <Route path="/lang/:countrycode" component={Home} />
                <Route path="/pages/:slug" component={Pages} />
                <Route path="/posts/:slug" component={Posts} />
                <Route path="/products/" component={AllProducts} />
                <Route path="/category/:slug/" component={Products} />
                <Route
                  exact
                  path="/checkout"
                  render={() => <Redirect to="/checkout/1" />}
                />
                <Route path="/checkout/:step" component={Checkout} />
                <Route path="/order-sent/" component={OrderSent} />
                <Route path="/user/" component={User} />
                <Route path="/newuser/" component={NewUser} />
                <Route path="/newuser-success/" component={NewUserSuccess} />
                <Route path="/info/" component={Info} />
                <Route path="/search-results/" component={SearchProducts} />
                <Route
                  path="/order-admin/orders"
                  component={OrderAdminOrders}
                />
                <Route path="/order-admin/users" component={OrderAdminUsers} />
                <Route
                  exact
                  path="/order-admin/user"
                  render={() => <Redirect to="/order-admin/users" />}
                />
                <Route
                  path="/order-admin/user/:userId"
                  component={OrderAdminUserDetailsPage}
                />
                <Route
                  exact
                  path="/order-admin/order"
                  render={() => <Redirect to="/order-admin/orders" />}
                />
                <Route
                  path="/order-admin/order/:orderId"
                  component={OrderAdminOrderDetailsPage}
                />
                <Route
                  path="/ce-admin/print-list"
                  component={CeAdminPrintList}
                />
                <Route
                  exact
                  path="/ce-och-motorintyg-sok"
                  component={CeOchMotorintygSok}
                />
              </Switch>
            </App>
          </BrowserRouter>
        </LocalizeProvider>
      </Provider>
    );
  }
}

ReactDOM.render(<Start />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

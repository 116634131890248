import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getActiveLanguage } from "react-localize-redux";
import { Header, Icon, Segment, Loader, Button } from "semantic-ui-react";
import config from "../config/config";
import { Link } from "react-router-dom";
import axios from "axios";

import { addProductToCart } from "../actions/wccd-content";
import { editCart } from "../actions/settings-action";
import ProductList from "../components/product-list";

const mountedStyle = {
  animation: "inAnimation 250ms ease-in",
};
const unmountedStyle = {
  animation: "outAnimation 270ms ease-out",
  animationFillMode: "forwards",
};

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "spareparts",
      serviceInfoHtml: "",
      serviceInfoPostId: "",
      lastCategorySlug: "",
      showZoomedImage: false,
      imageMapHtmlContent: "",
      imageMapSlug: "",
    };
  }

  componentDidMount() {
    let slugId = this.props.match.params.slug;
    this.props.editCart(false);
    if (this.state.imageMapHtmlContent === "") {
      this._getImageMap(slugId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let slugId = this.props.match.params.slug;
    if (!slugId.includes(this.props.mainProductSlug)) {
      console.log("DO SOME REDIRECTION");
    }

    if (slugId !== this.state.imageMapSlug) {
      this.setState({
        imageMapSlug: slugId,
        imageMapHtmlContent: "",
      });
      this._getImageMap(slugId);
    }

    if (
      prevState.lastCategorySlug !== "" &&
      prevState.lastCategorySlug !== slugId
    ) {
      this.setState({
        serviceInfoHtml: "",
        serviceInfoPostId: "",
        lastCategorySlug: "",
        activeTab: "spareparts",
        imageMapHtmlContent: "",
        imageMapSlug: "",
      });
    }
  }

  addToCart = (product) => {
    this.props.addProductToCart(product);
  };

  /** Private functions */

  _printPage = () => {
    window.print();
  };

  _getProducts(slugId) {
    let returnProducts = [];
    if (this.props.products.length <= 0) {
      return [];
    }
    let productList = JSON.parse(JSON.stringify(this.props.products));

    let category = this.props.categoriesArray[this.props.mainProductSlug].find(
      function(categoryItem) {
        return categoryItem.slug === slugId;
      }
    );
    if (category === undefined) {
      return [];
    }
    if (category.products.length > 0) {
      // category.products.sort(sortList('pos_num'));
      for (let i = 0; i < category.products.length; i++) {
        let item = category.products[i];
        let findObject = productList.find((productItem) => {
          return productItem.id === item.product_id;
        });
        let newObjectInArray = Object.assign({}, findObject);
        newObjectInArray.no_units = item.no_units;
        newObjectInArray.pos_num = item.pos_num;
        newObjectInArray.no_selectedItems = item.no_units;
        returnProducts.push(newObjectInArray);
      }
    }

    return returnProducts;
  }

  _getCategory(slugId) {
    let imageUrl = "";
    if (this.props.categoriesArray[this.props.mainProductSlug].length <= 0) {
      return { categoryImage: "", categoryTitle: "" };
    }
    let category = this.props.categoriesArray[this.props.mainProductSlug].find(
      (categoryItem) => {
        return categoryItem.slug === slugId;
      }
    );
    if (category === undefined) {
      return { categoryImage: "", categoryTitle: "" };
    }
    if (category.image !== null) {
      imageUrl = category.image.src;
    }
    return {
      categoryImage: imageUrl,
      categoryTitle: category.lang_name[this.props.currentLanguage.code],
      categoryDesc: category.lang_desc[this.props.currentLanguage.code],
    };
  }

  _getImageMap(slugId) {
    // let urlPath = config.API_ENDPOINTS.GET_IMAGE_MAP_BASE_URL + slugId;
    let urlPath =
      "https://spareparts.swepac.com/wp-json/wccd/v1/get-image-map/" + slugId;
    Promise.all([axios.get(urlPath)]).then((resp) => {
      if (resp) {
        if (resp.length > 0) {
          const responseData = resp[0].data;

          this.setState({
            imageMapHtmlContent: responseData.html,
          });
        }
      } else {
        this.setState({ imageMapHtmlContent: "", imageMapSlug: "" });
      }
    });
  }

  _getServiceInfo(slugId) {
    let urlPath = config.API_ENDPOINTS.GET_CATEGORY_SERVICE_INFO + slugId;
    Promise.all([axios.get(urlPath)]).then((resp) => {
      if (resp) {
        let savePostContent = "";
        let savePostId = "";
        if (resp[0].data !== false) {
          if (resp[0].data.post_content !== "") {
            savePostContent = resp[0].data.post_content;
            savePostId = resp[0].data.post_id;
          } else {
            savePostContent =
              "<em>No service information exist for this category.</em>";
          }
        } else {
          savePostContent =
            "<em>No service information exist for this category.</em>";
        }
        this.setState({
          serviceInfoHtml: savePostContent,
          serviceInfoPostId: savePostId,
          lastCategorySlug: slugId,
        });
      } else {
        this.setState({ serviceInfoHtml: "" });
      }
    });
  }

  contentClickHandler = (e) => {
    e.preventDefault();
    const targetLink = e.target.href;
    if (targetLink) {
      const pushLink =
        "/category/" + targetLink.substring(targetLink.lastIndexOf("/") + 1);
      this.props.history.push(pushLink);
    }
  };

  executeScroll = (elementRef) => {
    console.log("perform scroll now!");
    elementRef.current.scrollIntoView();
  };

  render() {
    if (this.props.mainProductSlug === undefined) {
      return <div>Product could not be found.</div>;
    }

    let slugId = this.props.match.params.slug; // Hämta slug från urlen (React Router), se index.js i roten
    let products = this._getProducts(slugId);
    const { categoryImage, categoryTitle, categoryDesc } = this._getCategory(
      slugId
    );

    let urlParamItems = [];
    const paramSearchUrlString = this.props.location.search;
    const urlParams = new URLSearchParams(paramSearchUrlString);
    urlParams.forEach((urlParamItem) => {
      if (!urlParamItems.includes(urlParamItem)) {
        const catMatchResult = this._getCategory(urlParamItem);
        urlParamItems.push({
          slug: urlParamItem,
          title: catMatchResult.categoryTitle,
        });
      }
    });

    let pdfUrlPath = `${config.printServiceBaseUrl}/swepac-product-print/?slug=${this.props.mainProductSlug}`
      + `&category_slug=${slugId}`
      + `&lang=${this.props.currentLanguage.code}`
      + `&currency_key=${this.props.currentCurrency}`;
    if (
      this.props.user.mainCustomerSystemId !== 0 &&
      !this.props.user.isOrderManager
    ) {
      pdfUrlPath += `&main_customer_id=${this.props.user.mainCustomerSystemId}`;
    }

    const currentCurrencyValue = this.props.currentCurrency;
    let currencySymbol = "";
    this.props.currencies.forEach(function(currencyItem) {
      if (currencyItem.key === currentCurrencyValue) {
        currencySymbol = currencyItem.symbol;
      }
    });
    let isDisplayingServiceInfoCategory = String(slugId).includes(
      "service-info"
    );
    let serviceInfoPostHtmlContent = "";
    let serviceInfoPostTitle = "";
    let serviceInfoPostId = "";

    if (this.props.location.state) {
      if (this.props.location.state.serviceInfoPostContent) {
        serviceInfoPostHtmlContent = this.props.location.state
          .serviceInfoPostContent;
      }
      if (this.props.location.state.serviceInfoPostName) {
        serviceInfoPostTitle = this.props.location.state.serviceInfoPostName;
      }
      if (this.props.location.state.serviceInfoPostId) {
        serviceInfoPostId = this.props.location.state.serviceInfoPostId;
      }
    }
    let serviceInfoPrintBaseUrl =
      `${config.printServiceBaseUrl}/swepac-service-info/?id=`;

    const urlQuery = new URLSearchParams(this.props.location.search);
    const highlightedItem = urlQuery.get("highlight") || "";

    // console.log("image map content: ", this.state.imageMapHtmlContent);

    return (
      <Fragment>
        {!isDisplayingServiceInfoCategory && (
          <Fragment>
            <div className="content display-block product-page-container">
              <div className="flex-1 margin-bottom-10">
                <Header as="h2" style={{ display: "flex" }}>
                  <span style={{ flex: 1 }}>{categoryTitle}</span>

                  {pdfUrlPath !== "" && this.state.activeTab === "spareparts" && (
                    <span className="link-black pdf-download-button">
                      <a
                        href={pdfUrlPath}
                        alt="Download PDF"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon
                          name="file pdf outline"
                          link
                          className="no-print"
                        />
                      </a>
                    </span>
                  )}
                  {this.state.activeTab === "serviceinfo" &&
                    this.state.serviceInfoPostId !== "" && (
                      <span className="link-black pdf-download-button">
                        <a
                          href={
                            serviceInfoPrintBaseUrl +
                            this.state.serviceInfoPostId
                          }
                          alt="Download PDF"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Icon
                            name="file pdf outline"
                            link
                            className="no-print"
                          />
                        </a>
                      </span>
                    )}
                </Header>
                {products.length < 1 && (
                  <span style={{ flex: 1 }}>{categoryDesc}</span>
                )}
              </div>
              {/*
COMMENTED OUT UNTIL THIS FUNCTIONALITY IS NEEDED:
							<Button.Group style={{ marginTop: "10px" }}>
								<Button
									onClick={() => this.setState({ activeTab: "spareparts" })}
									active={this.state.activeTab === "spareparts"}
									size='large'
									color='red'
									basic
								>Spare parts</Button>
								<Button
									onClick={() => { this.setState({ activeTab: "serviceinfo" }); this._getServiceInfo(slugId); }}
									active={this.state.activeTab === "serviceinfo"}
									size='large'
									color='red'
									basic
								>Service info</Button>
							</Button.Group> */}

              {this.state.activeTab === "serviceinfo" && (
                <Segment>
                  <Loader active={this.state.serviceInfoHtml === ""} />
                  {this.state.serviceInfoHtml !== "" && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.serviceInfoHtml,
                      }}
                    />
                  )}
                </Segment>
              )}
              {this.state.activeTab === "spareparts" && (
                <div
                  className="flex-container product-columns-container"
                  style={{ marginTop: "15px" }}
                >
                  <div className="flex-1 category-image-responsive">
                    {products.length > 0 && (
                      <span style={{ flex: 1 }}>{categoryDesc}</span>
                    )}
                    {categoryImage !== "" && (
                      <img
                        src={categoryImage}
                        alt=""
                        className="category-image"
                        onClick={() => this.setState({ showZoomedImage: true })}
                      />
                    )}
                  </div>

                  {products.length > 0 && (
                    <div className="flex-1 product-column-item">
                      <Fragment>
                        <div>
                          <ProductList
                            products={products}
                            user={this.props.user}
                            productAddToCart={this.addToCart}
                            currentLanguageCode={
                              this.props.currentLanguage.code
                            }
                            exchangeRate={this.props.exchangeRate}
                            currentCurrency={currentCurrencyValue}
                            currencySymbol={currencySymbol}
                            highlightedItem={highlightedItem}
                            executeScroll={this.executeScroll}
                          />
                        </div>
                      </Fragment>
                    </div>
                  )}
                  {products.length < 1 &&
                    this.state.imageMapHtmlContent !== "" && (
                      <div style={{ position: "relative" }}>
                        {/*  <img src="https://mycondesign.se/swepac/f75e.svg" width="640" height="480" alt="" useMap="#imagemap" /> */}
                        {/* <img src={categoryImage} alt="" useMap="#imagemap" />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.imageMapHtmlContent,
                          }}
                        /> */}
                        <div
                          className="dynamicimagemap dynamic-category-image-desktop"
                          onClick={this.contentClickHandler}
                          dangerouslySetInnerHTML={{
                            __html: this.state.imageMapHtmlContent,
                          }}
                        />
                        {urlParamItems.length > 0 && (
                          <div className="categorySelectPopupBox">
                            <div class="categorySelectCloseBoxButton">
                              <Link to={slugId}>
                                <Icon name="close" />{" "}
                              </Link>
                            </div>
                            <ul className="categorySelectPopupList">
                              {urlParamItems.map((urlParamItem) => {
                                return (
                                  <li>
                                    <Link to={urlParamItem.slug}>
                                      {urlParamItem.title}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                  {products.length < 1 &&
                    this.state.imageMapHtmlContent === "" && (
                      <img src={categoryImage} alt="" />
                    )}
                  <div className="flex-1 text-align-center category-image-desktop">
                    {/* {categoryImage !== "" && products.length > 0 && (
                      <img
                        src={categoryImage}
                        alt=""
                        className="category-image"
                        onClick={() => this.setState({ showZoomedImage: true })}
                      />
                    )} */}
                    {categoryImage !== "" &&
                      products.length > 0 &&
                      this.state.imageMapHtmlContent !== "" && (
                        <>
                          <div
                            onClick={this.contentClickHandler}
                            className="category-image-no-zoomin-container"
                            style={{ marginTop: "10px" }}
                            dangerouslySetInnerHTML={{
                              __html: this.state.imageMapHtmlContent,
                            }}
                          />
                          <Button
                            icon
                            className="category-image-no-zoomin-container-zoom-button"
                            onClick={() =>
                              this.setState({ showZoomedImage: true })
                            }
                          >
                            <Icon name="zoom" />
                          </Button>
                        </>
                      )}
                    {categoryImage !== "" &&
                      products.length > 0 &&
                      this.state.imageMapHtmlContent === "" && (
                        <>
                          <img
                            src={categoryImage}
                            style={{ marginTop: "10px" }}
                            alt=""
                            className="category-image-no-zoomin"
                          />
                          <br />
                          <Button
                            icon
                            onClick={() =>
                              this.setState({ showZoomedImage: true })
                            }
                          >
                            <Icon name="zoom" />
                          </Button>
                        </>
                      )}
                    {products.length > 0 && <p>{categoryDesc}</p>}
                  </div>
                </div>
              )}
            </div>
          </Fragment>
        )}
        {isDisplayingServiceInfoCategory && (
          <Fragment>
            <div className="content display-block product-page-container">
              <Header as="h2" style={{ display: "flex" }}>
                <span style={{ flex: 1 }}>{serviceInfoPostTitle}</span>
                {serviceInfoPostHtmlContent !== "" && (
                  <span className="link-black pdf-download-button">
                    <a
                      href={serviceInfoPrintBaseUrl + serviceInfoPostId}
                      alt="Download PDF"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon name="file pdf outline" link className="no-print" />
                    </a>
                  </span>
                )}
                <span style={{ color: "red", fontSize: "0.8em" }}>
                  Service info
                </span>
              </Header>

              <Segment>
                {serviceInfoPostHtmlContent !== "" && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: serviceInfoPostHtmlContent,
                    }}
                  />
                )}
                {serviceInfoPostHtmlContent === "" && (
                  <p>
                    <em>No service information exist for this category.</em>
                  </p>
                )}
              </Segment>
            </div>
          </Fragment>
        )}
        {/* <div
          className="zoomed-image"
          style={this.state.showZoomedImage ? mountedStyle : unmountedStyle}
        >
          <img
            src={categoryImage}
            alt=""
            onClick={() => this.setState({ showZoomedImage: false })}
          />
        </div> */}
        {categoryImage !== "" && this.state.showZoomedImage && (
          <div
            className="zoomed-image"
            style={this.state.showZoomedImage ? mountedStyle : unmountedStyle}
            onClick={() => this.setState({ showZoomedImage: false })}
          >
            <div
              className="zoomed-image-background"
              style={{ backgroundImage: `url(${categoryImage})` }}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  categoriesArray: state.wccd.categoriesArray,
  machineTypes: state.wccd.machineTypes,
  products: state.wccd.products,
  user: state.user,
  mainProductSlug: state.wccd.selectedProduct.slug,
  exchangeRate: state.wccd.currencyExchangeRate,
  currentCurrency: state.wccd.selectedCurrency,
  currencies: state.wccd.currencies,
  currentLanguage: getActiveLanguage(state.localize),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ addProductToCart, editCart }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Products);

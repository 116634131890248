import React, { Component } from "react";
import resetApp from "../functions/reset-app";

class ClearStoragePage extends Component {
  componentDidMount() {
    resetApp();
  }

  render() {
    return (
      <div>
        <p>Clearing local storage...</p>
      </div>
    );
  }
}

export default ClearStoragePage;

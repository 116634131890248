import { UPDATE_SETTING, TOGGLE_CART_EDIT, DO_STEP, EMPTY_CART, SET_ERROR } from '../actions/action-types';
import config from '../config/config';

export default function settings(
	state = {
		version: config.version,
		endpoints: config.API_ENDPOINTS,
		timeout: 600,
		step: 1,
		editCart: false,
		pages: [ 'category' ],

		currencyType: 'SEK',
		currencyLocales: 'sv-SE',
		currencyUpdated: '2018-06-28',
		currencies: {
			CNY: 0.74,
			INR: 7.71,
			JPY: 12.36,
			USD: 0.11,
			EUR: 0.1,
			SEK: 1
		},
		lang: 'sv',
		langCode: 'sv-SE',
		error: ''
	},
	action
) {
	switch (action.type) {
		case UPDATE_SETTING:
			return {
				...state
			};

		case TOGGLE_CART_EDIT:
			// console.log('TOGGLE_CART_EDIT - reducer', action.payload);
			return { ...state, editCart: action.payload.cart };

		case DO_STEP:
			return { ...state, step: action.payload.step };

		// Skickas från action-filen wccd-content emptyCart()
		case EMPTY_CART:
			return { ...state, step: 1 };

		case SET_ERROR:
			return { ...state, error: action.payload.msg };

		default:
			return state;
	}
}

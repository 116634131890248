import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Dimmer, Loader, Grid, Header, Icon, Table, Button, Modal, Checkbox, Divider, Input, Form } from 'semantic-ui-react';
import withRouter from 'react-router/withRouter';
import config from '../config/config';

import CeOchMotorintygSok from '../container/ce-och-motorintyg-sok';

class CeAdminPrintList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoadingScreen: false,
            confirmModalIsOpenWithSerialNumber: "",
            cePrintItems: [],
            markedPrintItems: [],
            isShowingSearchView: false,
            openCeDocumentSearchInput: ""
        };
    }

    acceptMultipleButtonClicked = () => {
        const selectedSerialNumbersString = this.state.markedPrintItems.join(",");
        this.acceptPrintButtonClicked(selectedSerialNumbersString);
    }

    acceptPrintButtonClicked = (serialNumber) => {
        this.setState({
            confirmModalIsOpenWithSerialNumber: serialNumber
        });
    }

    deselectAllRows = () => {
        this.setState({
            markedPrintItems: []
        });
    }

    acceptAllPrintItems = () => {
        this.setState({ showLoadingScreen: true });
        this.selectAllRows();
        setTimeout(() => {
            const selectedSerialNumbersString = this.state.markedPrintItems.join(",");
            this.setState({ showLoadingScreen: false });
            this.acceptPrintButtonClicked(selectedSerialNumbersString);
        }, 1000);
    }

    selectAllRows = () => {
        var selectedItems = [];
        this.state.cePrintItems.forEach(printItem => {
            selectedItems.push(printItem.serienummer);
        });
        this.setState({
            markedPrintItems: selectedItems
        });
    }

    closeModalWindow = (answer) => {
        if (answer) {
            const acceptSerialNumber = this.state.confirmModalIsOpenWithSerialNumber;
            this.setState({
                cePrintItems: [],
                confirmModalIsOpenWithSerialNumber: "",
                showLoadingScreen: true
            });
            const urlPath = config.API_ENDPOINTS.CE_ADMIN_ACCEPT_CE_PRINT + "?gId=" + this.props.user.gId + "&serialNumbers=" +
                acceptSerialNumber.replace(" ", "");
            Promise.all([
                axios.post(urlPath)
            ])
                .then((resp) => {
                    const response = resp[0].data;
                    if (response) {
                        this.setState({
                            showLoadingScreen: false
                        });
                        this.refreshPrintList();
                    }
                })
                .catch((error) => {
                    console.log('error allWccd', error);
                });
        } else {
            this.setState({
                confirmModalIsOpenWithSerialNumber: ""
            });
        }
    }

    checkBoxChanged = (serialNumber, checked) => {
        if (checked) {
            this.setState({
                markedPrintItems: this.state.markedPrintItems.concat([serialNumber])
            });
        } else {
            this.setState({
                markedPrintItems: this.state.markedPrintItems.filter(function (printItem) {
                    return printItem !== serialNumber
                })
            });
        }
    }

    headerRowCheckboxChanged = (e, { checked }) => {
        if (checked) {
            this.selectAllRows();
        } else {
            this.deselectAllRows();
        }
    }

    getPrintList = () => {
        this.setState({
            showLoadingScreen: true
        });
        const urlPath = config.API_ENDPOINTS.CE_ADMIN_GET_PRINT_LIST + "?gId=" + this.props.user.gId;
        Promise.all([
            axios.get(urlPath)
        ])
            .then((resp) => {
                const response = resp[0].data;
                this.setState({
                    cePrintItems: response,
                    showLoadingScreen: false
                });
            })
            .catch((error) => {
                console.log('error allWccd', error);
            });
    }

    refreshPrintList = () => {
        this.setState({
            markedPrintItems: [],
            cePrintItems: []
        });
        this.getPrintList();
    }

    openMultiPrintPage = (modelString) => {
        const urlToOpen = `${config.printServiceBaseUrl}/swepac-ce-motorintyg-admin-print/?models=${modelString}`;
        window.open(urlToOpen, '_blank');
    }

    openMultiprint = () => {
        const selectedSerialNumbersString = this.state.markedPrintItems.join(",");
        this.openMultiPrintPage(selectedSerialNumbersString);
    }

    openCeDocumentTextChanged = (e) => {
        this.setState({
            openCeDocumentSearchInput: e.target.value
        });
    }

    openCeDocumentFieldSubmit = () => {
        const searchValue = this.state.openCeDocumentSearchInput.trim()
        if (searchValue.length > 2) {
            this.openMultiPrintPage(searchValue);
        }
    }

    componentDidMount() {
        this.getPrintList();
    }

    render() {
        const acceptSerialNumbers = this.state.confirmModalIsOpenWithSerialNumber.replace(/,/g, ", ");
        return (
            <div className="content">
                <div className="flex-1">

                    <Modal open={this.state.confirmModalIsOpenWithSerialNumber !== ""} basic size='small'>
                        <Header icon='warning circle' content='Godkänn utskrift' />
                        <Modal.Content>
                            {this.state.markedPrintItems.length > 1 && this.state.markedPrintItems.length < this.state.cePrintItems.length &&
                                <p>
                                    Är du säker på att du vill godkänna utskriften av {acceptSerialNumbers}? Dessa kommer försvinna från listan och det går inte att ångra.
                                </p>
                            }
                            {this.state.markedPrintItems.length === this.state.cePrintItems.length &&
                                <p>
                                    Är du säker på att du vill godkänna utskriften av alla CE/Motorintyg? Alla poster kommer försvinna från listan och det går inte att ångra.
                                </p>
                            }
                            {this.state.markedPrintItems.length < 2 &&
                                <p>
                                    Är du säker på att du vill godkänna utskriften av {acceptSerialNumbers}? Den kommer försvinna från listan och det går inte att ångra.
                                </p>
                            }
                        </Modal.Content>
                        <Modal.Actions>
                            <Button basic color='red' inverted onClick={() => this.closeModalWindow(false)}>
                                <Icon name='remove' /> Nej
                            </Button>
                            <Button color='green' inverted onClick={() => this.closeModalWindow(true)}>
                                <Icon name='checkmark' /> Ja
                            </Button>
                        </Modal.Actions>
                    </Modal>

                    <Grid columns={1} stackable>
                        <Grid columns={2} stackable>
                            <Header as="h2"><Icon name='print' />Utskriftskö för senaste CE/Motorintyg</Header>
                            <Grid.Column>
                                <p>Här nedan visas nyligen importerade CE/Motorintyg-produkter som inte ännu blivit utskrivna.</p>
                                <Button
                                    active={!this.state.isShowingSearchView}
                                    attached='left'
                                    onClick={() => this.setState({ isShowingSearchView: false })}>Utskriftskö</Button>
                                <Button
                                    active={this.state.isShowingSearchView}
                                    attached='right'
                                    onClick={() => this.setState({ isShowingSearchView: true })}>CE/Motorintyg-sök</Button>
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                                <p>Öppna CE-dokument för serienummer:</p>
                                <Form onSubmit={this.openCeDocumentFieldSubmit}>
                                    <Input
                                        action={{ icon: 'angle right' }}
                                        placeholder='Ange ett serienummer...'
                                        value={this.state.openCeDocumentSearchInput}
                                        onChange={this.openCeDocumentTextChanged}
                                    />
                                </Form>
                            </Grid.Column>
                            <Divider />
                        </Grid>


                        {!this.state.isShowingSearchView &&
                            <Grid.Column>
                                <Grid columns={3} stackable>
                                    <Grid.Column>
                                        {this.state.markedPrintItems.length > 0 &&
                                            <Button.Group>
                                                <Button positive onClick={this.acceptMultipleButtonClicked} icon labelPosition='left'>
                                                    <Icon name='check' />
                                            Ta bort alla markerade från kön
                                        </Button>
                                                <Button onClick={this.deselectAllRows}>Avmarkera alla</Button>
                                            </Button.Group>
                                        }
                                        {this.state.markedPrintItems.length < 1 &&
                                            <Button positive onClick={this.acceptAllPrintItems} icon labelPosition='left'>
                                                <Icon name='check' />
                                        Ta bort alla från kön
                                    </Button>
                                        }
                                    </Grid.Column>
                                    {this.state.markedPrintItems.length < 1 &&
                                        <Grid.Column floated="right">
                                            <Button
                                                floated='right'
                                                disabled={this.state.markedPrintItems.length > 0}
                                                onClick={this.refreshPrintList}
                                                icon
                                                labelPosition='left'>
                                                <Icon name='refresh' />
                                        Uppdatera lista
                                    </Button>
                                        </Grid.Column>
                                    }
                                    {this.state.markedPrintItems.length > 0 &&
                                        <Grid.Column floated="right">
                                            <Button color="red" onClick={() => this.openMultiprint()} icon labelPosition="left" floated="right">
                                                <Icon name='print' />Multiprint</Button>
                                        </Grid.Column>
                                    }
                                </Grid>
                                <Table singleLine compact selectable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                <Checkbox
                                                    onChange={this.headerRowCheckboxChanged}
                                                    checked={this.state.markedPrintItems.length === this.state.cePrintItems.length}
                                                />
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>Modellnamn</Table.HeaderCell>
                                            <Table.HeaderCell>Serienummer</Table.HeaderCell>
                                            <Table.HeaderCell>Motorintyg</Table.HeaderCell>
                                            <Table.HeaderCell>CE-dokument</Table.HeaderCell>
                                            <Table.HeaderCell>Ta bort från kön</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {this.state.cePrintItems.map((printItem, index) => {
                                            const ceUrl = `https://spareparts.swepac.com/motorintyg/ce/index.php?modell=${printItem.serienummer}&print=true`;
                                            const motorintygUrl = `https://spareparts.swepac.com/motorintyg/index.php?modell=${printItem.serienummer}&print=true`;
                                            return (
                                                <Table.Row key={index}>
                                                    <Table.Cell><Checkbox onChange={(e, { checked }) => this.checkBoxChanged(printItem.serienummer, checked)} checked={this.state.markedPrintItems.includes(printItem.serienummer)} /></Table.Cell>
                                                    <Table.Cell>{printItem.modell}</Table.Cell>
                                                    <Table.Cell>{printItem.serienummer}</Table.Cell>
                                                    <Table.Cell>
                                                        <Button onClick={() => window.open(motorintygUrl, '_blank')}>Visa motorintyg</Button>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Button onClick={() => window.open(ceUrl, '_blank')}>Visa CE-certifikat</Button>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Button
                                                            disabled={this.state.markedPrintItems.length > 0}
                                                            positive
                                                            onClick={() => this.acceptPrintButtonClicked(printItem.serienummer)}>
                                                            Ta bort från kön
                                                    </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })
                                        }
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                        }
                        {this.state.isShowingSearchView &&
                            <Grid.Column>
                                <CeOchMotorintygSok></CeOchMotorintygSok>
                            </Grid.Column>
                        }
                    </Grid>
                    <Dimmer active={this.state.showLoadingScreen} style={{ "zIndex": "1" }} inverted><Loader /></Dimmer>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

CeAdminPrintList = connect(mapStateToProps)(CeAdminPrintList);
CeAdminPrintList = withRouter(CeAdminPrintList);

export default CeAdminPrintList;

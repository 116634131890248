import React from "react";
import { Translate } from "react-localize-redux";
import { Dropdown } from "semantic-ui-react";

const OrderAdminDropdown = (props) => {
  return (
    <Dropdown
      item
      simple={props.simple}
      fitted="vertically"
      trigger={
        <span>
          <Translate id="orderadmin_order_management" />
        </span>
      }
      className="top-menu-item"
    >
      <Dropdown.Menu>
        <Dropdown.Item
          icon="boxes"
          text={<Translate id="orderadmin_manage_orders" />}
          onClick={() => {
            props.gotoPage("/order-admin/orders");
          }}
        />
        <Dropdown.Item
          icon="users"
          text={<Translate id="orderadmin_manage_users" />}
          onClick={() => {
            props.gotoPage("/order-admin/users");
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default OrderAdminDropdown;

import React from "react";
import { Dropdown } from "semantic-ui-react";

const CeAdminDropdown = (props) => {
  return (
    <Dropdown
      item
      simple={props.simple}
      fitted="vertically"
      trigger={<span>CE/Motorintyg-admin</span>}
      className="top-menu-item"
    >
      <Dropdown.Menu>
        <Dropdown.Item
          icon="print"
          text="Utskriftskö för senaste CE/Motorintyg"
          onClick={() => {
            props.gotoPage("/ce-admin/print-list");
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CeAdminDropdown;

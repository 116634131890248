import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import Login from '../components/login';
import UserPage from '../components/user-page';
import checkUser from '../functions/check-user';

class MainHeader extends Component {
	render() {
		let userExist = checkUser(this.props.user);
		return (
			<Fragment>
				{userExist ? (
					<UserPage />
				) : (
					<Fragment>
						<Grid columns={3}>
							<Grid.Column />
							<Grid.Column>
								<Login />
							</Grid.Column>
							<Grid.Column />
						</Grid>
					</Fragment>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({}, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainHeader));

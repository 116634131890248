import {
  UPDATE_USER,
  LOGIN_USER,
  SAVE_ORDER_ADMIN_ORDERS,
  LOGOUT_USER,
  UPDATE_CHECKOUT_USER,
  UPDATE_CHECKOUT_USER_BILLING,
  UPDATE_CHECKOUT_USER_DELIVERY,
  BATCH_UPDATE_USER,
  REFRESH_USER,
  EMPTY_CHECKOUT_USER,
  ORDER_ADMIN_UPDATE_ORDER,
  ORDER_ADMIN_GET_USERS,
  ORDER_ADMIN_USERS_ACTIVE_TAB,
  ORDER_ADMIN_DISPLAY_NONEXPORT_ORDERS,
  ORDER_ADMIN_ORDERS_ACTIVE_TAB,
  ORDER_ADMIN_ORDERS_CHANGE_COUNTRIES,
  LOAD_SAVED_DATA_AFTER_UPDATE_RESTART,
  UPDATE_USER_PHONE,
} from "../actions/action-types";
import md5 from "md5";
// POST /auth Inloggning
// PUT /user/update (Skicka med gId)

const initalState = {
  id: 0,
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  company: "",
  mainCompany: "",
  mainCustomerId: "",
  mainCustomerSystemId: 0,
  discount: 0,
  delivery: {
    id: "",
    attn_first_name: "",
    attn_last_name: "",
    street: "",
    street_2: "",
    zip_code: "",
    postal_area: "",
    country: "",
    delivery_id: "",
  },
  deliveryTime: "",
  backorder_split_delivery: 0,
  deliveryAddresses: [],
  choosenDeliveryAddressId: 0,
  billing: {},
  customerOrderNum: "",
  parcelId: "",
  notes: "",
  guest: "",
  gId: "",
  checkOutUser: {},
  gravatar: "",
  isOrderManager: false,
  isCeAdmin: false,
  orderAdminOrders: [],
  orderAdminUsers: [],
  orderAdminActiveUserTabIndex: 1,
  orderAdminDisplayNonExportOrders: false,
  orderAdminActiveOrderTabIndex: 1,
  orderAdminSelectedLanguages: [],
};

export default function user(state = initalState, action) {
  switch (action.type) {
    case UPDATE_USER:
      console.log("reducer UPDATE_USER", action.payload);
      // name: name,
      // value: value
      return state;

    case BATCH_UPDATE_USER:
      // console.log('reducer BATCH_UPDATE_USER', action.payload);
      let stateBatchUser = Object.assign({}, state);
      stateBatchUser = {
        ...stateBatchUser,
        firstname: action.payload.user.first_name,
        lastname: action.payload.user.last_name,
        billing: action.payload.user.billing,
        checkOutUser: {},
      };
      return {
        ...stateBatchUser,
        checkOutUser: stateBatchUser,
      };

    case UPDATE_CHECKOUT_USER:
      // console.log('reducer UPDATE_CHECKOUT_USER', action.payload);
      let newCheckoutUserState = Object.assign({}, state.checkOutUser);
      newCheckoutUserState[action.payload.name] = action.payload.value;
      return {
        ...state,
        checkOutUser: newCheckoutUserState,
      };

    case EMPTY_CHECKOUT_USER:
      // console.log('reducer EMPTY_CHECKOUT_USER', action);
      let existUser = state;
      let existState = Object.assign({}, state);
      existState = {
        ...existState,
        id: existUser.id,
        gId: existUser.gId,
        firstname: existUser.firstname,
        lastname: existUser.lastname,
        refName: existUser.firstname + " " + existUser.lastname,
        phone: existUser.phone,
        email: existUser.email,
        guest: existUser.guest,
        mainCompany: existUser.mainCompany,
        mainCustomerId: existUser.mainCustomerId,
        mainCustomerSystemId: existUser.mainCustomerSystemId,
        discount: existUser.discount,
        deliveryAddresses: existUser.deliveryAddresses,
        billing: existUser.billing,
        checkOutUser: {},
        gravatar: md5(existUser.email),
        isOrderManager: existUser.isOrderManager,
        isCeAdmin: existUser.isCeAdmin,
        orderAdminOrders: existUser.orderAdminOrders,
        orderAdminUsers: existUser.orderAdminUsers,
        orderAdminActiveUserTabIndex: existUser.orderAdminActiveUserTabIndex,
        orderAdminDisplayNonExportOrders:
          existUser.orderAdminDisplayNonExportOrders,
        orderAdminActiveOrderTabIndex: existUser.orderAdminActiveOrderTabIndex,
        orderAdminSelectedLanguages: existUser.orderAdminSelectedLanguages,
      };
      console.log("EMPTY_CHECKOUT_USER", existState);
      // return state;

      return {
        ...existUser,
        checkOutUser: existState,
      };

    case UPDATE_CHECKOUT_USER_BILLING:
      // console.log('UPDATE_CHECKOUT_USER_BILLING', action.payload);
      let newCheckoutBilling = Object.assign({}, state.checkOutUser.billing);
      newCheckoutBilling[action.payload.name] = action.payload.value;
      return {
        ...state,
        checkOutUser: {
          ...state.checkOutUser,
          billing: newCheckoutBilling,
        },
      };

    case UPDATE_CHECKOUT_USER_DELIVERY:
      let newCheckoutDelivery = Object.assign({}, state.checkOutUser);
      newCheckoutDelivery.delivery = action.payload.address;
      newCheckoutDelivery.choosenDeliveryAddressId = action.payload.address.id;
      return {
        ...state,
        checkOutUser: newCheckoutDelivery,
      };
    // console.log('Reducer - Delivery', newCheckoutDelivery);
    // return state;

    case ORDER_ADMIN_UPDATE_ORDER:
      let returnNewState2 = Object.assign({}, state);
      let orderItemId = action.payload.orderId;
      let orderItemNewStatus = action.payload.newStatus;
      let shouldDelete = action.payload.shouldDelete;

      let orderAdminOrdersResult2 = returnNewState2.orderAdminOrders;
      let orderObject = orderAdminOrdersResult2.find((orderItem) => {
        return orderItem.ID.toString() === orderItemId.toString();
      });
      let orderObjectIndex = orderAdminOrdersResult2.indexOf(orderObject);
      if (orderItemNewStatus !== "") {
        if (orderObjectIndex !== -1) {
          orderObject.order_status = orderItemNewStatus;
          if (!shouldDelete) {
            orderAdminOrdersResult2[orderObjectIndex] = orderObject;
          } else {
            orderAdminOrdersResult2.splice(orderObjectIndex, 1);
          }
        }
      }
      returnNewState2 = {
        ...returnNewState2,
        orderAdminOrders: orderAdminOrdersResult2,
      };
      return {
        ...returnNewState2,
      };

    case LOGIN_USER:
    case REFRESH_USER:
      // console.log('reducer LOGIN_USER');
      let user = action.payload.user;
      let newState = Object.assign({}, state);

      newState = {
        ...newState,
        id: user.user_id,
        gId: user.gId,
        firstname: user.first_name,
        lastname: user.last_name,
        refName: user.first_name + " " + user.last_name,
        phone: user.phone,
        email: user.email,
        guest: user.guest,
        mainCompany: user.main_customer,
        mainCustomerId: user.main_customer_id,
        mainCustomerSystemId: user.main_customer_system_id,
        discount: user.discount,
        delivery: user.selected_delivery_address,
        deliveryAddresses: user.delivery_addresses,
        billing: user.billing_address,
        gravatar: md5(user.email),
        isOrderManager: user.is_order_manager,
        isCeAdmin: user.is_ce_certificates_admin,
      };
      return {
        ...newState,
        checkOutUser: newState,
      };

    case UPDATE_USER_PHONE:
      let returnNewState7 = Object.assign({}, state);
      returnNewState7 = {
        ...returnNewState7,
        phone: action.payload.newPhoneNumber,
      };
      return {
        ...returnNewState7,
      };

    case SAVE_ORDER_ADMIN_ORDERS:
      let orderAdminOrdersResult = action.payload.orderAdminOrders;
      let returnNewState = Object.assign({}, state);
      returnNewState = {
        ...returnNewState,
        orderAdminOrders: orderAdminOrdersResult,
      };
      return {
        ...returnNewState,
      };

    case ORDER_ADMIN_GET_USERS:
      let orderAdminUsersResult = action.payload.orderAdminUsers;
      let returnNewState3 = Object.assign({}, state);
      returnNewState3 = {
        ...returnNewState3,
        orderAdminUsers: orderAdminUsersResult,
      };
      return {
        ...returnNewState3,
      };

    case ORDER_ADMIN_USERS_ACTIVE_TAB:
      let newActiveTabIndex = action.payload.activeTabIndex;
      let returnNewState4 = Object.assign({}, state);
      returnNewState4 = {
        ...returnNewState4,
        orderAdminActiveUserTabIndex: newActiveTabIndex,
      };
      return {
        ...returnNewState4,
      };

    case ORDER_ADMIN_DISPLAY_NONEXPORT_ORDERS:
      let newDisplayNonExportOrdersValue =
        action.payload.displayNonExportOrders;
      let returnNewState5 = Object.assign({}, state);
      returnNewState5 = {
        ...returnNewState5,
        orderAdminDisplayNonExportOrders: newDisplayNonExportOrdersValue,
      };
      return {
        ...returnNewState5,
      };

    case ORDER_ADMIN_ORDERS_ACTIVE_TAB:
      let newActiveTabIndex2 = action.payload.activeTabIndex;
      let returnNewState6 = Object.assign({}, state);
      returnNewState6 = {
        ...returnNewState6,
        orderAdminActiveOrderTabIndex: newActiveTabIndex2,
      };
      return {
        ...returnNewState6,
      };

    case ORDER_ADMIN_ORDERS_CHANGE_COUNTRIES:
      let newSelectedCountriesPayload = action.payload.newSelectedCountries;
      let returnNewState8 = Object.assign({}, state);
      returnNewState8 = {
        ...returnNewState8,
        orderAdminSelectedLanguages: newSelectedCountriesPayload,
      };
      return {
        ...returnNewState8,
      };

    case LOGOUT_USER:
      // console.log('reducer LOGOUT_USER', action.payload);
      if (localStorage.getItem("savedUserData") !== null) {
        localStorage.removeItem("savedUserData");
      }
      return initalState;

    case LOAD_SAVED_DATA_AFTER_UPDATE_RESTART:
      return action.payload.userData;

    default:
      return state;
  }
}

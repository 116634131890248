import React, { Component, Fragment } from "react";
import { Translate } from "react-localize-redux";
import { Icon, Input, Table, Responsive } from "semantic-ui-react";

import currency, { formatCurrency } from "../functions/currency";
import checkUser from "../functions/check-user";

class ProductListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: this.props.products,
      productId: null,
      showExtraInfo: 0,
      column: null,
      direction: null,
    };

    this.highlightedRowRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.products !== this.props.products) {
      this.setState({ products: nextProps.products });
    }
  }

  /** Private functions */

  _toggleInfo = (id) => {
    if (this.state.showExtraInfo === id) {
      this.setState({ showExtraInfo: 0 });
    } else {
      this.setState({ showExtraInfo: id });
    }
  };

  _handleSort = (clickedColumn) => () => {
    /* Verkar inte funka som det ska??
		let { column, products, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				products: products.concat().sort(this._sortBy(clickedColumn)),
				direction: 'ascending'
			});
			return;
		}
		this.setState({
			products: products.reverse(),
			direction: direction === 'ascending' ? 'descending' : 'ascending'
		}); */
  };

  _sortBy = (key) => {
    return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
  };

  _sendToCart = (productId) => {
    let noOfProductsSelected = this.state.products.find((products) => {
      return products.id === productId;
    });
    let valuesToCart = {
      productId: noOfProductsSelected.id,
      numOfProducts: noOfProductsSelected.no_selectedItems,
    };
    this.props.productAddToCart(valuesToCart);
  };

  _noOfProducts = (e) => {
    var reg = new RegExp("^\\d+$");
    if (reg.test(e.target.value) && parseInt(e.target.value) > 0) {
      this.state.products.forEach((element) => {
        if (element.id === parseInt(e.target.name)) {
          element.no_selectedItems = parseInt(e.target.value);
        }
      });
      this.setState({ products: this.state.products });
    }
  };

  _createMarkupPage(pageContent) {
    return { __html: pageContent };
  }

  render() {
    const { products, showExtraInfo, column, direction } = this.state;
    const { currentLanguageCode, user } = this.props;
    let userExist = checkUser(user);
    let userIsOrderManager = false;
    if (user.isOrderManager) {
      userIsOrderManager = true;
    }
    let responsiveMaxWidth = 767;

    setTimeout(() => {
      if (this.highlightedRowRef.current) {
        this.highlightedRowRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 500);

    let headerColumnMaxWidth = "60px";

    return (
      <Table
        striped
        compact="very"
        color="red"
        selectable
        size="small"
        style={{ fontSize: "0.9em" }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              textAlign="left"
              sorted={column === "pos_num" ? direction : null}
              onClick={this._handleSort("pos_num")}
              style={{ maxWidth: headerColumnMaxWidth }}
            >
              <Translate id="pos" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "sku" ? direction : null}
              onClick={this._handleSort("sku")}
              style={{ maxWidth: headerColumnMaxWidth }}
            >
              <Translate id="artno" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "name" ? direction : null}
              onClick={this._handleSort("name")}
              style={{
                minWidth: "200px",
                maxWidth: headerColumnMaxWidth,
                overflow: "hidden",
              }}
            >
              <Translate id="description" />
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="left"
              style={{ maxWidth: headerColumnMaxWidth }}
            >
              <Translate id="units" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "price" ? direction : null}
              onClick={this._handleSort("price")}
              textAlign="left"
              className="no-print"
              style={{
                minWidth: "90px",
                maxWidth: headerColumnMaxWidth,
              }}
            >
              <Translate id="list_price" />
            </Table.HeaderCell>

            <Table.HeaderCell
              textAlign="left"
              className="no-print"
              style={{
                minWidth: "90px",
                maxWidth: headerColumnMaxWidth,
              }}
            >
              <Translate id="net_price" />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="left" className="no-print">
              <Translate id="number_of" />
            </Table.HeaderCell>
            <Table.HeaderCell className="no-print">&nbsp;</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {products.map((item, index) => {
            if (item === undefined) {
              console.log("item undefined - product list");
              return null;
            }
            let itemPrice = (
              parseFloat(item.price) / this.props.exchangeRate
            ).toString();
            let { floatPrice, strPrice } = currency(
              itemPrice,
              this.props.currentCurrency,
              this.props.currencySymbol
            );
            floatPrice = parseFloat(item.price);
            let discountPrice = 0;
            const caretDirection = showExtraInfo === item.id ? "up" : "down";
            if (!userExist) {
              strPrice = "";
            }
            if (item.sku === ">") {
              return (
                <Table.Row key={item.id}>
                  <td />
                  <td className="font-bold" colSpan="7">
                    {item.lang_name[currentLanguageCode]}
                  </td>
                </Table.Row>
              );
            } else if (item.sku === "<") {
              return (
                <Table.Row key={item.id}>
                  <td colSpan="8">
                    <hr />
                  </td>
                </Table.Row>
              );
            } else {
              if (this.props.user.discount > 0 && floatPrice !== 0) {
                discountPrice =
                  floatPrice - floatPrice * (this.props.user.discount / 100);
              } else {
                discountPrice = floatPrice;
              }
              discountPrice = discountPrice / this.props.exchangeRate;
              let rowShouldBeHighlighted = false;
              let backgroundColor = "transparent";
              let fontWeight = "normal";
              let boxShadow = "none";
              if (this.props.highlightedItem === "" + item.pos_num) {
                rowShouldBeHighlighted = true;
                backgroundColor = "yellow";
                fontWeight = "bold";
                boxShadow = "rgba(255,0,0,0.2) 0 0 10px";
              }
              return (
                <Fragment key={index}>
                  {item.lang_name !== undefined &&
                    item.lang_desc !== undefined && (
                      <Table.Row
                        verticalAlign="top"
                        key={item.id}
                        style={{
                          background: backgroundColor,
                          fontWeight: fontWeight,
                          boxShadow: boxShadow,
                        }}
                        negative={rowShouldBeHighlighted}
                      >
                        <Table.Cell textAlign="left">
                          {rowShouldBeHighlighted && (
                            <span ref={this.highlightedRowRef} />
                          )}
                          <Responsive
                            as={Fragment}
                            maxWidth={responsiveMaxWidth}
                            minWidth={0}
                          >
                            <b>
                              <Translate id="pos" />:
                            </b>{" "}
                          </Responsive>
                          {item.pos_num !== 0 && item.pos_num}
                        </Table.Cell>
                        <Table.Cell>
                          <Responsive
                            as={Fragment}
                            maxWidth={responsiveMaxWidth}
                            minWidth={0}
                          >
                            <b>
                              <Translate id="artno" />:
                            </b>{" "}
                          </Responsive>
                          {item.sku}
                        </Table.Cell>
                        <Table.Cell>
                          <Responsive
                            as={Fragment}
                            maxWidth={responsiveMaxWidth}
                            minWidth={0}
                          >
                            <b>
                              <Translate id="description" />:
                            </b>{" "}
                          </Responsive>
                          {item.lang_name[currentLanguageCode]}{" "}
                          {item.lang_desc[currentLanguageCode] !== "" && (
                            <Icon
                              name={"angle " + caretDirection}
                              className="show-cursor no-print"
                              onClick={() => {
                                this._toggleInfo(item.id);
                              }}
                            />
                          )}
                          {showExtraInfo === item.id && (
                            <div
                              dangerouslySetInnerHTML={this._createMarkupPage(
                                item.lang_desc[currentLanguageCode]
                              )}
                            />
                          )}
                        </Table.Cell>

                        <Table.Cell textAlign="left">
                          <Responsive
                            as={Fragment}
                            maxWidth={responsiveMaxWidth}
                            minWidth={0}
                          >
                            <b>
                              <Translate id="units" />:
                            </b>{" "}
                          </Responsive>
                          {item.no_units}
                        </Table.Cell>
                        <Table.Cell textAlign="left" className="no-print">
                          <Responsive
                            as={Fragment}
                            maxWidth={responsiveMaxWidth}
                            minWidth={0}
                          >
                            <b>
                              <Translate id="list_price" />:
                            </b>{" "}
                          </Responsive>
                          {strPrice}
                        </Table.Cell>
                        <Table.Cell textAlign="left" className="no-print">
                          <Responsive
                            as={Fragment}
                            maxWidth={responsiveMaxWidth}
                            minWidth={0}
                          >
                            <b>
                              <Translate id="net_price" />:
                            </b>{" "}
                          </Responsive>
                          {userExist &&
                            !userIsOrderManager &&
                            formatCurrency(
                              discountPrice,
                              this.props.currentCurrency,
                              this.props.currencySymbol
                            )}
                        </Table.Cell>
                        <Table.Cell textAlign="left" className="no-print">
                          <Responsive
                            as={Fragment}
                            maxWidth={responsiveMaxWidth}
                            minWidth={0}
                          >
                            <b>
                              <Translate id="number_of" />:
                            </b>{" "}
                          </Responsive>
                          {userExist && !userIsOrderManager && (
                            <Input
                              type="text"
                              name={item.id}
                              value={item.no_selectedItems}
                              style={{ width: 50 }}
                              onChange={this._noOfProducts}
                              size="small"
                              className="product-list"
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell textAlign="center" className="no-print">
                          {userExist && !userIsOrderManager && (
                            <Icon
                              link
                              name="add to cart"
                              onClick={() => this._sendToCart(item.id)}
                            />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )}
                </Fragment>
              );
            }
          })}
        </Table.Body>
      </Table>
    );
  }
}
export default ProductListItem;

import React, { Component, Fragment } from 'react';
import { Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

class TreeListItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nodeData: {},
			menuDisplay: { maxHeight: 0 },
			menuOpen: false,
			selectedNodesId: []
		};
	}

	/** Private functions */

	/**
	 * _clickLinkLastItem
	 * @param {string} path path to router
	 */
	_clickLinkLastItem = (path) => {
		this.props.history.push('/category/' + path);
		this.props.handleCategoryClick(path);
	};

	/**
	 * _clickLinkParentItem
	 * @param {string} slug slug
	 * @param {int} id Item id
	 */
	_clickLinkParentItem = (slug, id) => {
		let selectedNodeId = this.state.selectedNodesId.indexOf(id);
		if (selectedNodeId < 0) {
			this.setState({ selectedNodesId: [ ...this.state.selectedNodesId, id ] });
		}
		this._clickLinkLastItem(slug);
	};

	/**
	 * _clickCaretParentItem
	 * @param {string} slug slug
	 * @param {int} id Item id
	 */
	_clickCaretParentItem = (slug, id) => {
		let selectedNodeId = this.state.selectedNodesId.indexOf(id);
		if (selectedNodeId < 0) {
			this.setState({ selectedNodesId: [ ...this.state.selectedNodesId, id ] });
			this._clickLinkLastItem(slug);
		} else {
			let newSelectedNodesArray = Array.from(this.state.selectedNodesId);
			newSelectedNodesArray.splice(selectedNodeId, 1);
			this.setState({ selectedNodesId: newSelectedNodesArray });
		}
	};

	/**
	 * _getAllChildren
	 * @param {object} item Parent kategori
	 * @returns {array} Returnerar en JSX-array med ul och li med children nodes för montering
	 */
	_getAllChildren = (item) => {
		const { selectedNodesId } = this.state;
		const languageCode = this.props.currentLanguageCode;
		let returnJSX = [];

		let index = 0;
		item.forEach((element) => {
			let mainItem = [];

			let childItems = [];
			if (element.children.length > 0) {
				let childrenClass = 'hidden-node';
				let caretIcon = 'caret down';
				let classSelectedNode = 'tree-not-selected show-cursor';
				if (selectedNodesId.indexOf(element.id) >= 0) {
					childrenClass = '';
					caretIcon = 'caret up';
					classSelectedNode = 'tree-selected show-cursor';
				}
				mainItem.push(
					<Icon
						key={index}
						link
						name={caretIcon}
						onClick={() => {
							this._clickCaretParentItem(element.slug, element.id);
						}}
					/>
				);
				index++;
				mainItem.push(
					<span
						className={classSelectedNode}
						key={index}
						onClick={() => {
							this._clickLinkParentItem(element.slug, element.id);
						}}
					>
						{element.pos_num}. {element.lang_name[languageCode]}
					</span>
				);
				index++;
				childItems.push(
					<ul key={index} className={childrenClass}>
						{this._getAllChildren(element.children)}
					</ul>
				);
				index++;
				returnJSX.push(
					<li key={element.id}>
						{mainItem}
						{childItems}
					</li>
				);
				index++;
			} else {
				mainItem.push(
					<span className="show-cursor margin-left-tiny" key={index}>
						{element.lang_name[languageCode]}
					</span>
				);
				index++;
				returnJSX.push(
					<li
						key={element.id}
						onClick={() => this._clickLinkLastItem(element.slug)}
						className={this.props.activeNode === element.slug ? 'tree-active tree-view-li' : 'tree-view-li'}
					>
						{element.pos_num}. {mainItem}
					</li>
				);
				index++;
			}
		});
		return returnJSX;
	};

	_buildTree(item) {
		const languageCode = this.props.currentLanguageCode;
		const { selectedNodesId } = this.state;

		let returnJSX = [];
		let index = 0;

		item.children.sort(function(a, b) {
			return a.pos_num - b.pos_num;
		});

		if (item.children.length > 0) {
			let childrenClass = 'hidden-node';
			let caretIcon = 'caret down';
			let classSelectedNode = 'tree-not-selected show-cursor';
			if (selectedNodesId.indexOf(item.id) >= 0) {
				childrenClass = '';
				caretIcon = 'caret up';
				classSelectedNode = 'tree-selected show-cursor';
			}
			returnJSX.push(
				<Icon
					key={index}
					link
					name={caretIcon}
					style={{ fontSize: 16 }}
					onClick={() => {
						this._clickCaretParentItem(item.slug, item.id);
					}}
				/>
			);
			index++;
			returnJSX.push(
				<span
					className={classSelectedNode}
					key={index}
					onClick={() => {
						this._clickLinkParentItem(item.slug, item.id);
					}}
				>
					{item.pos_num}. {item.lang_name[languageCode]}
				</span>
			);
			index++;

			returnJSX.push(
				<ul key={index} className={childrenClass}>
					{this._getAllChildren(item.children)}
				</ul>
			);
			return <li key={item.id}>{returnJSX}</li>;
		} else {
			returnJSX.push(
				<Fragment key={index}>
					{' '}
					{item.pos_num}. <span className="show-cursor margin-left-tiny">{item.lang_name[languageCode]}</span>
				</Fragment>
			);
			return (
				<li
					onClick={() => this._clickLinkLastItem(item.slug)}
					key={item.id}
					className={this.props.activeNode === item.slug ? 'tree-active tree-view-li' : 'tree-view-li'}
				>
					<Fragment>{returnJSX}</Fragment>
				</li>
			);
		}
	}
	render() {
		return <Fragment>{this._buildTree(this.props.props)}</Fragment>;
	}
}

export default withRouter(TreeListItem);